import React from "react";

import { CSSTransition } from "react-transition-group";
import { formatStatisticsValue } from "../../../utils";
import { ROUTES } from "../../routers";
import { useNavigate } from "react-router-dom";

// images
const bg = require("./burning-icon.svg").default as string;
const coin = require("./coin.png") as string;
const mainBg = require("../../template/img/main-bg.png") as string;

interface IProps {
  onClick: () => void;
  checkActivityData: any;
  showBurningModal: boolean;
}

export const BurningModal: React.FC<IProps> = ({
  onClick,
  checkActivityData,
  showBurningModal,
}) => {
  const navigate = useNavigate();

  return (
    <CSSTransition
      in={showBurningModal}
      timeout={1000}
      classNames="fade runeModal"
      unmountOnExit
    >
      <div
        className="overlay app-modal-root blur"
        style={{
          backgroundImage: `url(${mainBg})`,

          display: "flex",
        }}
      >
        <div
          className="close-modal ab"
          onClick={onClick}
          style={{ zIndex: 1000000 }}
        >
          x
        </div>
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          style={{
            position: "relative",
            // justifyContent: "space-between",
            flexDirection: "column",
            backgroundColor: "transparent",
            justifyContent: "center",
            padding: "25px 25px 40px 25px",
            height: "100%",
            flex: 1,
            backgroundImage: `url(${bg})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div
            className="app-modal-content task-modal-content"
            style={{ height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p
                className="ab"
                style={{
                  fontSize: "35px",
                  color: "#D6D6E7",
                  marginBottom: "50px",
                }}
              >
                Whoosh...
              </p>
              <p
                style={{
                  fontSize: "20px",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "30px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                You wasn’t active for
                <span
                  className="ab"
                  style={{ fontSize: "50px", color: "#D6D6E7" }}
                >
                  {checkActivityData?.data?.days_inactive}
                </span>
                days
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <img width="63px" height="63px" src={coin} alt="" />
                <p
                  className="ab"
                  style={{
                    fontSize: "55px",
                    color: "#D6D6E7",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {checkActivityData?.data?.amount
                    ? formatStatisticsValue(checkActivityData?.data?.amount, 2)
                    : null}
                </p>
              </div>
              <p
                style={{
                  fontSize: "20px",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "30px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                were burned
              </p>
            </div>
          </div>
          <a
            style={{ margin: "0 auto" }}
            onClick={(event) => {
              event.preventDefault();
              onClick();
              navigate(ROUTES.PAY_PASS.PATH);
            }}
          >
            <button
              className="ab"
              style={{
                width: "243px",
                height: "23px",
                color: "#fff",
                alignItems: "center",
                textAlign: "center",
                margin: "0 auto",
                padding: "7px 0",
                border: "3px solid #D6D6E7",
                borderRadius: "16px",
                fontSize: "20px",
                background: "rgba(255, 255, 255, 0.2)",
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
                transition: "box-shadow 0.3s ease",
                boxShadow: "0 0 10px #D6D6E7",
              }}
            >
              Get protection
            </button>
          </a>
        </div>
      </div>
    </CSSTransition>
  );
};
