import React from "react";

import { ROUTES } from "../../routers";
const galaxyIcon = require("../img/galaxy-icon.png") as string;
const treasuresIcon = require("../img/treasures.png") as string;

export const PagesLinks: React.FC = () => {
  return (
    <div className="page-claim-actions">
      <>
        <a
          href={`#${ROUTES.PAY_PASS.PATH}`}
          className="page-claim-leaderboard-link ab"
        >
          <img width="152px" height="40.76px" src={galaxyIcon} />
        </a>
        <a
          href={`#${ROUTES.CHESTS.PATH}`}
          className="page-claim-leaderboard-link ab"
        >
          <img width="173px" height="40.76px" src={treasuresIcon} />
        </a>
      </>
    </div>
  );
};
