import React from "react";

import { beginCell, Cell, toNano } from "@ton/ton";
import { CHAIN, SendTransactionRequest, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useHapticFeedback, useInitData, WebAppUser } from "@vkruglikov/react-telegram-web-app";

import {
  useAuthMutation,
  useGetExternalChestsMeQuery,
  useUpdateChestsOpenMutation,
} from "../feature/users";
import { IUpdateChestsOpen } from "../feature/users/models";
import { UserContext } from "../contexts/user-context";
import { LottieAnimationContext } from "../contexts/lottie-animation-context";
import { ROUTES } from "../routers";

import { CHEST_ONBOARDING_KEY } from "./consts";
import { TChest, EModalType, TKey } from "./models";

export const useConnectWalletApi = () => {
    // Wallets connections
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const wallet = useTonWallet();
    const address = wallet?.account?.address;
    const [initDataUnsafe] = useInitData();
    const [impactOccurred] = useHapticFeedback();

  // @ts-ignore
  const notAcceptedWallet = wallet?.name === "HOT";
  // @ts-ignore
  const isNetNotAccepted = React.useMemo(
    () => wallet?.account?.chain === CHAIN.TESTNET || notAcceptedWallet,
    [notAcceptedWallet, wallet?.account?.chain]
  );

  const handleDisconnect = async () => {
    try {
      impactOccurred('heavy')
      await tonConnectUI.disconnect();
    } catch (e) {
      console.log("Error during disconnect:", e);
    }
  };

  React.useEffect(() => {
    if (isNetNotAccepted) {
      handleDisconnect();
      // setShowNetError(true);
    }
  }, [handleDisconnect, isNetNotAccepted]);

    const handleSentTransaction = async (currentKey: TKey, orderId: string, onSuccessTransaction: (orderId: string, transaction_id: string) => void) => {
      if (isNetNotAccepted) {
        handleDisconnect();
      } else {
      if (!wallet || !wallet.account?.address) {
        console.error("Wallet not connected");
        return;
      }

      if (address && orderId) {
        const transaction: SendTransactionRequest = {
          validUntil: Math.floor(Date.now() / 1000) + 3600,
          messages: [
            {
              address: "UQB6mWfpPkVNpyQqQM5na2XgKktstZgx_HATUl5mAmfWwbq9",
              amount: toNano(currentKey.ton).toString(),
              payload: beginCell()
              .storeUint(0, 32)
              .storeStringTail(`orderId: ${orderId}, userId: ${initDataUnsafe?.user?.id || ""}`)
              .endCell().toBoc().toString("base64"),
            },
          ],
        };
    
        try {
          if (address) {
            // setIsPreparing(true);
            await tonConnectUI.sendTransaction(transaction).then((res) => {
              if (res?.boc) {
                const cell = Cell.fromBase64(res.boc);
                const buffer = cell.hash();
                const hashHex = buffer.toString("hex");
  
                if (hashHex) {
                  onSuccessTransaction(orderId, hashHex);
                }
              }
            }).catch((e) => {
              console.log(e, 'trasaction error')
            });


          }
        } catch (error) {
          console.log(error, 'error');
          // setIsPreparing(false);
        }        
      }
      }
    };

    return {
      isWalletConnected: tonConnectUI.connected,
      address,
      tonConnectUI,
      setOptions,
      onDisconnect: handleDisconnect,
      handleSentTransaction,
    }
}

export const useOnboardingApi = () => {
  const [showOnboarding, setShowOnboarding] = React.useState<boolean>(false);
  const [impactOccurred] = useHapticFeedback();

  React.useEffect(() => {
    const isShowed = localStorage.getItem(CHEST_ONBOARDING_KEY);

    if (!isShowed) {
      setShowOnboarding(true);
    }

  },[]);

  const handleShowOnboarding = (value?: boolean) => {
    if (typeof value === "boolean") {
      setShowOnboarding(value);
      impactOccurred('heavy')
    } else {
      setShowOnboarding((prevState) => !prevState);
      impactOccurred('heavy')
    }

    localStorage.setItem(CHEST_ONBOARDING_KEY, "true");
  };


  return {
    showOnboarding,
    onShowOnboarding: handleShowOnboarding,
  };
};

export const usePageChestApi = (
) => {
  const { setUserState, isLoading } = React.useContext(UserContext);

  const [currentChest, setCurrentChest] = React.useState<TChest | null>(null);
  const [modalType, setModalType] = React.useState<EModalType | null>(null);
  const [currentReward, setCurrentReward] = React.useState<IUpdateChestsOpen | null>(null);
  const [showConfetti, setShowConfetti] = React.useState(false);
  const { setIsVisible } = React.useContext(LottieAnimationContext);

  React.useEffect(() => {
    if (showConfetti) {
      setIsVisible?.(true);
    } else {
      setIsVisible?.(false);
    }
  }, [setIsVisible, showConfetti]);

  const [impactOccurred] = useHapticFeedback();

  // user data hooks
  const [initDataUnsafe, initData] = useInitData();
  const telegramUser: WebAppUser = initDataUnsafe?.user as WebAppUser;
  const [auth, authState] = useAuthMutation();
  const [blockCollentBtn, setBlockCollentBtn] = React.useState(false);
   
  const { data: chests, isLoading: isChetsLoading } =
    useGetExternalChestsMeQuery();
  const [updateChest, updateChestState] = useUpdateChestsOpenMutation();

  const handleRefecthUserData = () => {

    auth({
      username: telegramUser?.id?.toString(),
      password: telegramUser?.id?.toString(),
      // @ts-ignore
      hash: initData,
    }).unwrap().then((res) => {
      if (res?.user) {
        setUserState?.(true, res?.user);
        window.location.hash = ROUTES.MAIN.PATH
      }
    })
  }

  const handleOpenChest = () => {
    impactOccurred('heavy')
    if (currentChest?.type) {
      setShowConfetti(true);
      setBlockCollentBtn(true)

      setTimeout(() => {
        setShowConfetti(false);
        setBlockCollentBtn(false);
      }, 4000);
      
      updateChest({ type: currentChest.type })
        .unwrap()
        .then((res) => {
            setCurrentReward(res)
            setModalType(EModalType.REWARD);
            impactOccurred("heavy");
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };

  const handleModalTypeClick = (value: EModalType | null) => {
    impactOccurred('heavy')
    setCurrentChest(null);
    setModalType(value);
  };

  const handleChestClick = (chest: TChest, value: EModalType) => {
    impactOccurred('heavy')
    setCurrentChest(chest);
    setModalType(value);
  };

  const handleCloseModal = () => {
    impactOccurred('heavy')
    setCurrentChest(null);
    setModalType(null);
  };

  const handleChestsModalFormOpenPage = () => {
    impactOccurred('heavy');
    setModalType(EModalType.DETAILS);
  };

  return {
    chests,
    isUserLoading: isLoading,
    isLoading: isChetsLoading,
    isFetching: updateChestState.isLoading || authState.isLoading,
    currentChest,
    modalType,
    onModalTypeClick: handleModalTypeClick,
    onChestClick: handleChestClick,
    onCloseModal: handleCloseModal,
    onChestsModalFormOpenPage: handleChestsModalFormOpenPage,
    onOpenChest: handleOpenChest,
    currentReward,
    blockCollentBtn,
    onRefecthUserData: handleRefecthUserData,
  };
};
