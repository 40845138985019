import React from "react";

import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { CSSTransition } from "react-transition-group";

import "../../app-modal/index.scss";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM, RUNE_REWARD } from "../../../consts";
import { formatValues } from "../../../utils";
import { ERune, TRune, TStepModalContent } from "../../hooks/rune/models";
import { TSkins, TTask } from "../../feature/users/models";

import "./styles.scss";
import { Spinner } from "../../spinner";

// images
const wIcon = require("../../hooks/rune/img/w-icon.png") as string;
const cIcon = require("../../hooks/rune/img/c-icon.png") as string;
const oIcon = require("../../hooks/rune/img/o-icon.png") as string;
const iIcon = require("../../hooks/rune/img/i-icon.png") as string;
const nIcon = require("../../hooks/rune/img/n-icon.png") as string;

const COMMUNITY_LINK = "https://t.me/+_3owp7mWC4VlYjM8";

interface IProps {
  show: boolean;
  rune: TRune | null;
  onClick: (value: boolean) => void;
  content: TStepModalContent;
  isLoading?: boolean;
  onSetRuneStep: (
    runeStep: TRune,
    newData?: {
      balance: number;
      skins: TSkins | undefined;
      tasks: TTask | undefined;
    }
  ) => void;
}

export const RuneModal: React.FC<IProps> = ({
  show,
  rune,
  onClick,
  isLoading,
  content,
  onSetRuneStep,
}) => {
  const webApp = useWebApp();

  const handleLastStep = () => {
    if (rune?.step === ERune.N) {
      onSetRuneStep({
        step: ERune.DONE,
        completed: true,
      });
      webApp.openTelegramLink(COMMUNITY_LINK);
    } else if (rune?.step === ERune.DONE) {
      webApp.openTelegramLink(COMMUNITY_LINK);
    }
  };

  const isLastStep = rune?.step === ERune.N || rune?.step === ERune.DONE;

  return (
    <CSSTransition
      in={show}
      timeout={100}
      classNames="fade runeModal"
      unmountOnExit
    >
      <div className="overlay app-modal-root blur">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          style={{ position: "relative" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Spinner isLoading={!!isLoading} />
          <div className="close-modal ab" onClick={() => onClick(false)}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              {content?.icon && <img width="100px" src={content.icon} alt="" />}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <div className="task-modal-reward rune-description">
                  <p
                    className="task-modal-reward ab rune-description"
                    style={{ fontSize: "22px" }}
                  >
                    {content?.title}
                  </p>

                  <p style={{ fontSize: "16px" }}>{content?.message}</p>

                  {rune?.step === ERune.DONE && (
                    <>
                      <div className="task-modal-reward ab">
                        <SmallCoin
                          width={BALANCE_COIN_SIZE_MEDIUM}
                          height={BALANCE_COIN_SIZE_MEDIUM}
                        />
                        <p>+{formatValues(RUNE_REWARD)}</p>
                      </div>
                      <div className="runes">
                        <img width="45px" src={wIcon} alt="" />
                        <img width="45px" src={cIcon} alt="" />
                        <img width="45px" src={oIcon} alt="" />
                        <img width="45px" src={iIcon} alt="" />
                        <img width="45px" src={nIcon} alt="" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="rune-modal-button-wrapper"
              onClick={
                isLastStep ? () => handleLastStep() : () => onClick(false)
              }
            >
              {content?.button}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
