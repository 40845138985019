import React from "react";

import { Button, Typography } from "@mui/material";

interface IProps {
  onClick?: () => void;
  label: string | React.ReactElement;
  fontSize?: string;
  disableButton?: boolean;
}

export const ActionButton: React.FC<IProps> = ({
  onClick,
  label,
  fontSize,
  disableButton,
}) => {
  return (
    <Button
      onClick={onClick}
      className={disableButton ? "btn-disabled" : ""}
      sx={{
        background: "#9747FF",
        width: "100%",
        borderRadius: "22px",
        height: "48px",
      }}
    >
      <Typography
        className="ab"
        sx={{
          color: "#fff",
          fontSize: fontSize ?? "20px",
          textTransform: "none",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};
