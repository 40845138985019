import React from "react";

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM, TELEGRAM_LINK } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";
import { ITask } from "../../type";

interface IProps {
  currentTask?: ITask | null;
  onClose: () => void;
  onClaim: () => void;
  payPassMultiplier?: number;

}

const VERSION_TO_SHARE = "7.8";

export const ShareModal: React.FC<IProps> = ({
  currentTask,
  onClose,
  onClaim,
  payPassMultiplier,
}) => {
    const webApp = useWebApp();

  // @ts-ignore
  const version = window?.Telegram?.WebApp?.version <= VERSION_TO_SHARE;

  const getDescription = () => {
    if (currentTask?.is_done) {
        return "Received";
    } else if (version) {
      return "Share a story to your profile to claim a reward";
    } 

    return 'Please update your Telegram app to complete a task';

  };

  const share = () => {
    onClaim();
    const mediaUrl =
      "https://alohomora-tasks.fra1.cdn.digitaloceanspaces.com/images/1x.jpg";

    // @ts-ignore
      if (window?.Telegram?.WebApp?.shareToStory) {
      // @ts-ignore
        window.Telegram.WebApp.shareToStory(mediaUrl, {
          text: "test",
          widget_link: {
            url: "https://example.com",
            name: "test"
          }
        });

      } else {
        console.error("shareToStory error");
      }
  };

  const handleOpenLink = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  }

  return (
    <>
      {!!currentTask && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="close-modal ab" onClick={onClose}>
              x
            </div>

            <div className="task-modal-desc">
              <div className="task-modal-icon">
                {<img src={currentTask?.icon} alt="" />}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                <p className="task-modal-received">{getDescription()}</p>
              </div>
              <div className="task-modal-button-wrapper">
                {
                  <>
                    <div className="task-modal-reward ab">
                      <SmallCoin
                        width={BALANCE_COIN_SIZE_MEDIUM}
                        height={BALANCE_COIN_SIZE_MEDIUM}
                      />
                      <p>+{formatValues(currentTask?.reward)}</p> <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
                    </div>
                  </>
                }
              </div>
            </div>

            {currentTask?.is_done ? (
              <button
                onClick={handleOpenLink}
                className={`app-modal-content-button`}
              >
                {currentTask?.btnLabel}
              </button>
            ) : (
              <button
                onClick={share}
                className={`app-modal-content-button ${
                  version ? "" : "btn-disabled"
                }`}
              >
                Share
              </button>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
