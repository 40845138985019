import React from "react";

import { Stack, Typography } from "@mui/material";

import { EKeyType, TKey } from "../../../models";
import { KEYS_DATA } from "../../../chest-list/consts";

const bestDealsIcon = require("../../../img/best-deals-icon.png") as string;
const keyIcon = require("./img/key-icon.png") as string;

interface IProps {
  currentKey: TKey | null;
  onChange: (value: TKey) => void;
}

export const KeysContentList: React.FC<IProps> = ({ currentKey, onChange }) => {
  const isCurrentKey = (type: EKeyType) => type === currentKey?.type;

  return (
    <Stack
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gap="16px"
      width="94%%"
      justifyItems="center"
    >
      {KEYS_DATA.map((key) => (
        <Stack
          key={key.type}
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          position="relative"
          sx={{
            maxWidth: "156px",
            width: "100%",
            height: "auto",
            display: "flex",
            borderRadius: "24px",
            border: `4px solid #D6D6E7`,
            padding: "17px",
            background: isCurrentKey(key.type)
              ? "linear-gradient(90deg, #FFFFFF 0%, #E0EFFF 25%, #D6D6E7 50%, #C3C3D5 75%, #E0D8FF 90%, #FFFFFF 100%)"
              : "transparent",
          }}
          onClick={() => onChange(key)}
        >
          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <img src={keyIcon} alt="" width="69.94px" height="81.6px" />
            <Typography
              className="ab"
              style={{
                fontSize: "36px",
                color: isCurrentKey(key.type) ? "#000" : "#D6D6E7",
              }}
            >
              {key.amount}
            </Typography>
          </Stack>
          {key.isBestDeal ? (
            <img
              src={bestDealsIcon}
              alt=""
              width="56.25px"
              height="56.25px"
              style={{ position: "absolute", top: "-28px", right: "-28px" }}
            />
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
};
