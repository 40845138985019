import React from "react";

import { CSSTransition } from "react-transition-group";

// images
const bg = require("./burning-icon.svg").default as string;
const coin = require("./coin.png") as string;
const mainBg = require("../../template/img/main-bg.png") as string;

interface IProps {
  onClick: () => void;
  showFirstBurningNotificationModal: boolean;
}

export const BurningNotificationModal: React.FC<IProps> = ({
  onClick,
  showFirstBurningNotificationModal,
}) => {
  return (
    <CSSTransition
      in={showFirstBurningNotificationModal}
      timeout={10000}
      classNames="fade runeModal"
      unmountOnExit
    >
      <div
        className="overlay app-modal-root blur"
        style={{
          backgroundImage: `url(${mainBg})`,
          backdropFilter: "blur(4px)",
          WebkitBackdropFilter: "blur(4px)",
          backgroundSize: 'cover',

          display: "flex",
        }}
      >
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            position: "relative",
            height: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
            backgroundColor: "transparent",
            padding: "25px 25px 40px 25px",
            flex: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="app-modal-content task-modal-content"
            style={{ height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className="ab"
                style={{
                  fontSize: "28px",
                  color: "#D6D6E7",
                  marginBottom: "50px",
                  textAlign: "center",
                }}
              >
                Balance burning <p>is here!</p>
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "50px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                Log in every 3 days to avoid losing 5% daily. Fewer inactive
                wallets mean more tokens and higher value for you.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#898989",
                  textAlign: "center",
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                  width: "90%",
                }}
              >
                P.S. W-Galaxy owners will be protected from W-Burn
              </p>
            </div>
          </div>
          <button
            onClick={onClick}
            className="ab"
            style={{
              width: "243px",
              height: "23px",
              color: "#fff",
              alignItems: "center",
              textAlign: "center",
              margin: "0 auto",
              padding: "7px 0",
              border: "3px solid #D6D6E7",
              borderRadius: "16px",
              fontSize: "20px",
              background: "rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(4px)",
              WebkitBackdropFilter: "blur(4px)",
              transition: "box-shadow 0.3s ease",
              boxShadow: "0 0 10px #D6D6E7",
            }}
          >
            OK
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};
