import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";

import { CHESTS } from "./consts";
import { EChestNames, TChest, EModalType } from "../models";

const smallKeyIcon = require("./img/small-key-icon.png") as string;
const bestDealsIcon = require("../img/best-deals-icon.png") as string;

const BORDER_COLOR: Record<string, string> = {
  [EChestNames.STARTER]: "#D6D6E7",
  [EChestNames.GUARDIAN]: "#D6D6E7",
  [EChestNames.TREASURE]: "#F7CE45",
  [EChestNames.WCOSMIC]: "#C569CE",
};

interface IProps {
  currentChest: TChest | null;
  onChestClick: (chest: TChest, modalType: EModalType) => void;
  balance?: number;
}

export const ChestList: React.FC<IProps> = ({
  currentChest,
  onChestClick,
  balance,
}) => {
  const isCurrentChest = (name: string) => name === currentChest?.name;

  return (
    <Stack
      display="flex"
      flex="1"
      alignItems="center"
      justifyContent="flex-srart"
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        {CHESTS.map((chest) => {
          return (
            <Stack
              key={chest.name}
              width="45%"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="relative"
              sx={{
                minHeight: "157px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "24px",
                border: `4px solid ${BORDER_COLOR[chest.type]}`,
                padding: "10px",
                gap: "16px",
                background: isCurrentChest(chest.name)
                  ? "linear-gradient(90deg, #FFFFFF 0%, #E0EFFF 25%, #D6D6E7 50%, #C3C3D5 75%, #E0D8FF 90%, #FFFFFF 100%)"
                  : "transparent",
              }}
              onClick={() => onChestClick(chest, EModalType.DETAILS)}
            >
              {chest.isBestDeal ? (
                <img
                  src={bestDealsIcon}
                  alt=""
                  width="56.25px"
                  height="56.25px"
                  style={{ position: "absolute", top: "-28px", right: "-28px" }}
                />
              ) : null}
              <Typography
                className="ab"
                sx={{
                  fontSize: "19px",
                  color: isCurrentChest(chest.name) ? "#000" : "#D6D6E7",
                }}
              >
                {chest.name}
              </Typography>
              <Box width="84.27px" height="71.85px">
                <img
                  width="84.27px"
                  height="71.85px"
                  src={chest.icon}
                  alt={chest.name}
                />
              </Box>
              <ProgressBar
                completed={balance || 0}
                maxCompleted={chest.amount}
                bgColor="#A385EA"
                baseBgColor="#E1DBEF"
                height="11px"
                labelSize="0"
                width="100%"
                transitionTimingFunction="linear"
                className="mates-progress claim-progress"
              />
              <Stack
                display="flex"
                flexDirection="row"
                gap={0.5}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={smallKeyIcon}
                  alt=""
                  width="22.44px"
                  height="27.35px"
                />
                <Typography
                  className="ab"
                  sx={{
                    color: isCurrentChest(chest.name) ? "#000" : "#fff",
                    fontSize: "18px",
                  }}
                >
                  {balance}/{chest.amount}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
