import React from "react";
import { useCheckTaskTheeFriendsApi, usePageMatesApi } from "./hooks";

import "./index.scss";

import { Link } from "react-router-dom";
import { MatesList } from "./mates-list";
import { Invite } from "./invite";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";
import { MatesModal } from "./mates-modal";
import { VerifyModal } from "./verify-modal";

import { formatValues, getShareLink, isPayPasPurchased } from "../../utils";
import {
  ZoomInAnimation,
  RightAnimation,
  BottomAnimation,
} from "../page-animation";
import { TemplatePages } from "../template-pages";
import { GOLD_COLOR } from "../../consts";
import { MATES_MULTIPLIER } from "./consts";
const bg = require("./img/mates-bg.jpg") as string;
const mainIcon = require("./img/main.png") as string;
const premiumIcon = require("./img/premium-icon.png") as string;

const TELEGRAM_LINK = "https://telegra.ph/How-mates-work-04-05";

export const PageMates: React.FC<{}> = () => {
  const {
    isLoading,
    isFetching,
    mates,
    isLastLevel,
    currentLevelParams,
    onUpdateMatesLevel,
    onGetClaimAll,
    onModalClose,
    isOpenModal,
    onInviteMate,
    payPassMultiplier,
    handleRefetchUsers,
  } = usePageMatesApi();

  const links = getShareLink();

  const {
    isVerify,
    verifyModalOpen,
    onCloseVerifyModalOpen,
    onVerify,
    isFetching: isMatesFetching,
    inviteMateCount,
  } = useCheckTaskTheeFriendsApi(mates?.count_mates);

  const onBlur = !!verifyModalOpen || !!isOpenModal;

  return (
    <TemplatePages
      isLoading={isLoading}
      isFetching={isFetching || isMatesFetching}
      bg={bg}
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      {onBlur && (
        <div className={`overlay app-modal-root ${onBlur ? "blur" : ""}`}></div>
      )}
      <MatesModal amount={isOpenModal} onClose={onModalClose} />
      <VerifyModal
        isVerify={isVerify}
        open={verifyModalOpen}
        payPassMultiplier={payPassMultiplier}
        onClose={onCloseVerifyModalOpen}
        onVerify={onVerify}
        inviteMateCount={inviteMateCount}
      />

      <div className="page page-1 main-wrapper">
        <div className="content mates-content">
          <div className="mates-content-wrapper">
            <div className="mates-description">
              <h2 className="main-heading ab">
                Mates <img width="46px" src={mainIcon} alt="" />
              </h2>
              <div>
                <div className="mates-main-desc">
                  <div>
                    {isLastLevel ? (
                      <>
                        <p
                          className="plain-text font-size-15 mates-content-text common-color"
                          style={{ marginBottom: 16 }}
                        >
                          Congratulations, you have reached the last level!
                        </p>
                        <p className="plain-text font-size-15 mates-content-text common-color">
                          You earn {currentLevelParams?.rewards}% for each
                          friend
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          className="plain-text font-size-15 mates-content-text common-color"
                          style={{ marginBottom: 16 }}
                        >
                          Bring friends, earn more!
                        </p>
                        <p
                          className="plain-text font-size-15 mates-content-text common-color"
                          style={{ lineHeight: "20px" }}
                        >
                          Each new friend nets you{" "}
                          {isPayPasPurchased(payPassMultiplier) &&
                          payPassMultiplier &&
                          currentLevelParams?.reward?.old ? (
                            <b
                              style={{
                                color: GOLD_COLOR,
                              }}
                            >
                              {formatValues(
                                currentLevelParams.reward.old *
                                  payPassMultiplier
                              )}{" "}
                            </b>
                          ) : (
                            <>
                              <b
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {formatValues(currentLevelParams?.reward?.old)}
                              </b>{" "}
                              <b
                                style={{
                                  color: "red",
                                  textDecoration: "underline",
                                }}
                              >
                                {currentLevelParams?.reward?.old
                                  ? formatValues(
                                      currentLevelParams.reward.old *
                                        MATES_MULTIPLIER
                                    )
                                  : ""}
                              </b>{" "}
                            </>
                          )}
                          W-Coin (
                          {isPayPasPurchased(payPassMultiplier) &&
                          payPassMultiplier &&
                          currentLevelParams?.reward?.old ? (
                            <b
                              style={{
                                color: GOLD_COLOR,
                              }}
                            >
                              {formatValues(
                                currentLevelParams?.premiumReward?.old *
                                  payPassMultiplier
                              )}{" "}
                            </b>
                          ) : (
                            <>
                              <b
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {formatValues(
                                  currentLevelParams?.premiumReward?.old
                                )}
                              </b>{" "}
                              <b
                                style={{
                                  color: "red",
                                  textDecoration: "underline",
                                }}
                              >
                                {currentLevelParams?.premiumReward?.old
                                  ? formatValues(
                                      currentLevelParams.premiumReward.old *
                                        MATES_MULTIPLIER
                                    )
                                  : ""}
                              </b>
                            </>
                          )}
                          &nbsp;W-Coin with <img src={premiumIcon} alt="" />{" "}
                          Premium) plus <b>{currentLevelParams?.rewards}%</b> of
                          all their rewards
                        </p>
                      </>
                    )}
                  </div>
                  <ZoomInAnimation>
                    <div
                      style={{ flex: "unset" }}
                      className={`${isLastLevel && "mates-max-level"}`}
                    >
                      <div className="mates-content-desc-level-wrapper bebebe">
                        <p className="mates-content-desc-level gold-color ab">
                          {currentLevelParams?.level}
                        </p>
                        <p className="mates-content-desc-level-desc gold-color">
                          Level
                        </p>
                      </div>
                    </div>
                  </ZoomInAnimation>
                </div>
              </div>
              {!isLastLevel && (
                <RightAnimation>
                  <Link
                    target="_blank"
                    className="font-size-16 mates-tg-link purple-color"
                    to={TELEGRAM_LINK}
                  >
                    How mates work?
                  </Link>
                </RightAnimation>
              )}
            </div>
            {!isLastLevel && (
              <Invite
                data={currentLevelParams}
                matesCount={mates?.count_mates || 0}
                onClaimMateLevel={onUpdateMatesLevel}
              />
            )}
            <MatesList
              handleRefetchUsers={handleRefetchUsers}
              mates={mates}
              onGetClaimAll={onGetClaimAll}
            />
          </div>
          <BottomAnimation>
            <div style={{ display: "flex", gap: "12px" }}>
              <button
                className="main-button mate-button new-bottom"
                onClick={onInviteMate}
              >
                <b>Invite a Mate</b>
              </button>

              <a
                style={{
                  width: "100%",
                  textAlign: "center",
                  maxWidth: "100%",
                }}
                href={links?.shareLink}
              >
                <button className="main-button mate-button new-bottom">
                  <b>Share a link</b>
                </button>
              </a>
            </div>
          </BottomAnimation>
        </div>
      </div>
    </TemplatePages>
  );
};
