import React from "react";

import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { IMate } from "../../../feature/users/models";

import "./index.scss";

// images
const premiumIcon = require("../../img/premium-icon.png") as string;

interface IProps {
  mate?: IMate;
}

export const MatesListItem: React.FC<IProps> = ({ mate }) => {
  // @ts-ignore
  const { telegram_username } = mate;

  const shortName = (name?: string) => {
    return name
      ? name
          ?.split(" ")
          .map((word) => word[0])
          .join("")
          .toLocaleUpperCase()
      : "UN";
  };

  return (
    <>
      <div className="list-item-root bebebe">
        <div className="list-item--username-stack">
          <div className="list-item-short-username blur-bg-strong">
            <p>{shortName(telegram_username)}</p>
          </div>
          <div className="list-item-username-wrapper">
            {mate?.is_premium && <img src={premiumIcon} alt="" />}
            <p className="list-item-username common-color">
              {telegram_username}
            </p>
          </div>
        </div>
        <div className="list-item-balance">
          <p className="common-color">{formatValues(mate?.reward)}</p>
          <SmallCoin
            width={BALANCE_COIN_SIZE_SMALL}
            height={BALANCE_COIN_SIZE_SMALL}
          />
        </div>
      </div>
    </>
  );
};
