import React from "react";

import { Stack, Typography } from "@mui/material";
import { TonConnectUI } from "@tonconnect/ui-react";

import { KeysContentList } from "../keys-content-list";
import { ActionButtons } from "../actions-buttons";
import { TKey } from "../../../models";
import { IPendingOrder } from "../../../../feature/users/models";
import { ECurrentPurchaseType } from "../models";

interface IProps {
  currentKey: TKey | null;
  address?: string;
  tonConnectUI: TonConnectUI;
  onDisconnect: () => Promise<void>;
  onCurrentKey: (value: TKey) => void;
  onOpenCheckTransaction: () => void;
  onBuyKeys: () => void;
  openedStarsOrders?: IPendingOrder[];
  openedTonOrders?: IPendingOrder[];
  onBuyKeyBuyStars: () => void;
  currentPurchaseType: ECurrentPurchaseType | null;
}

export const Keys: React.FC<IProps> = ({
  currentKey,
  address,
  tonConnectUI,
  onDisconnect,
  onCurrentKey,
  onOpenCheckTransaction,
  onBuyKeys,
  openedStarsOrders,
  openedTonOrders,
  onBuyKeyBuyStars,
  currentPurchaseType,
}) => {
  return (
    <>
      <Stack
        display="flex"
        flexDirection="column"
        gap={2}
        justifyContent="center"
      >
        <Typography
          className="ab"
          style={{ fontSize: "35px", color: "#D6D6E7", margin: "0 auto" }}
          variant="h4"
          component="h2"
        >
          Key Shop
        </Typography>
        <Typography
          style={{ fontSize: "16px", color: "#D6D6E7", textAlign: "center" }}
        >
          W-Keys are used to open W-Chests
          <br /> and receive special rewards.
        </Typography>
      </Stack>
      <KeysContentList currentKey={currentKey} onChange={onCurrentKey} />
      <ActionButtons
        currentKey={currentKey}
        tonConnectUI={tonConnectUI}
        address={address}
        onDisconnect={onDisconnect}
        onOpenCheckTransaction={onOpenCheckTransaction}
        onBuyKeys={onBuyKeys}
        openedStarsOrders={openedStarsOrders}
        openedTonOrders={openedTonOrders}
        onBuyKeyBuyStars={onBuyKeyBuyStars}
        currentPurchaseType={currentPurchaseType}
      />
    </>
  );
};
