import React from "react";

import { Typography, Stack, Button } from "@mui/material";

import { EChestNames, EModalType, TChest } from "../../models";
import { BenefitList } from "./benefit-list";

const smallKeyIcon = require("../img/small-key-icon.png") as string;

interface IProps {
  currentChest: TChest | null;
  balance?: number;
  onModalTypeClick: (value: EModalType) => void;
  onOpenChest: () => void;
}

export const ChestContent: React.FC<IProps> = ({
  currentChest,
  balance,
  onModalTypeClick,
  onOpenChest,
}) => {
  const canOpenChest = React.useMemo(() => {
    if (currentChest?.amount && balance) {
      return balance >= currentChest.amount;
    }

    return false;
  }, [currentChest, balance]);

  return (
    <Stack
      className="app-modal-content task-modal-content"
      style={{ justifyContent: "space-between" }}
      overflow="auto"
    >
      <Typography
        className="ab"
        style={{ fontSize: "35px", color: "#D6D6E7" }}
        variant="h4"
        component="h2"
      >
        {currentChest?.name}
      </Typography>
      <Stack gap={2}>
        <img
          src={currentChest?.icon}
          alt=""
          height="189.86px"
          style={{
            margin: "0 auto",
            width:
              currentChest?.type === EChestNames.TREASURE || EChestNames.WCOSMIC
                ? "227.2px"
                : "257.2px",
          }}
        />
        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex="1"
        >
          <Typography
            className="ab"
            style={{
              fontSize: "24px",
              color: "#fff",
              textAlign: "center",
            }}
          >
            Chances to win:
          </Typography>
          <BenefitList type={currentChest?.type} />
        </Stack>
      </Stack>
      <Stack gap={2} width="100%" justifyContent="center">
        <Stack
          display="flex"
          flexDirection="row"
          gap="8px"
          alignItems="center"
          width="100%"
          justifyContent="center"
        >
          <img src={smallKeyIcon} width="23.44x" height="27.35px" alt="" />
          <Typography
            className="ab"
            sx={{
              color: "#fff",
              fontSize: "24px",
            }}
          >
            {balance}/{currentChest?.amount}
          </Typography>
        </Stack>
        {canOpenChest ? (
          <Button
            onClick={onOpenChest}
            sx={{
              background: "linear-gradient(to right, #8254BD 3%, #C333E7 100%)",
              width: "100%",
              borderRadius: "22px",
            }}
          >
            <Typography
              className="ab"
              sx={{
                color: "#fff",
                fontSize: "24px",
                textTransform: "none",
              }}
            >
              Open
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => onModalTypeClick(EModalType.KEY_SHOP)}
            sx={{
              background: "linear-gradient(to right, #8254BD 3%, #C333E7 100%)",
              width: "100%",
              borderRadius: "22px",
            }}
          >
            <Typography
              className="ab"
              sx={{
                color: "#fff",
                fontSize: "24px",
                textTransform: "none",
              }}
            >
              Get more W-Keys
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
