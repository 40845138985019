import React from "react";

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { ITask } from "../../type";
import { BALANCE_COIN_SIZE_MEDIUM, TELEGRAM_LINK } from "../../../../consts";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";
import { formatValues } from "../../../../utils";

const icon = require("./img/icon.png") as string;

interface IProps {
  currentTask?: ITask | null;
  onClose: () => void;
  onClaim: () => void;
  payPassMultiplier?: number;
}

export const CheckCodeModal: React.FC<IProps> = ({
  currentTask,
  onClose,
  onClaim,
  payPassMultiplier,
}) => {
  const webApp = useWebApp();

  const getDescription = () => {
    if (currentTask?.is_done) {
      return "Received";
    } else {
      return "Watch W-Space and find a code";
    }
  };

  const handleOpenExternalLink = () => {
    if (currentTask?.link) {
      webApp.openLink(currentTask.link);
    }
  };

  const handleOpenLink = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  };

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [inputCode, setInputCode] = React.useState<string | undefined>(
    undefined
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;

    if (Boolean(value)) {
      setInputCode(value as string);
    } else {
      setInputCode(undefined);
    }

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const submitCode = () => {
    if (currentTask?.checkCode !== inputCode) {
      setErrorMessage("Invalid code");
    } else {
      onClaim();
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <>
      {!!currentTask && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div className="close-modal ab" onClick={onClose}>
              x
            </div>

            <div className="task-modal-desc">
              <div style={{ width: "300px", height: "151.79px" }}>
                <img src={icon} alt="" width="300px" />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                <p className="task-modal-received">{getDescription()}</p>
              </div>
              <div className="task-modal-button-wrapper">
                {
                  <>
                    <div className="task-modal-reward ab">
                      <SmallCoin
                        width={BALANCE_COIN_SIZE_MEDIUM}
                        height={BALANCE_COIN_SIZE_MEDIUM}
                      />
                      <p>+{formatValues(currentTask?.reward)}</p>{" "}
                      <PayPassMultiplierComponent
                        payPassMultiplier={payPassMultiplier}
                      />
                    </div>
                  </>
                }
              </div>
            </div>
            {currentTask?.is_done ? null : (
              <div>
                <div className="okx-input-root">
                  <div className="okx-input-wrapper">
                    <input
                      value={inputCode}
                      type="text"
                      className="okx-mobile-input"
                      placeholder="Input code here"
                      onChange={(e) => onInputChange(e)}
                      onBlur={scrollToBottom}
                    />
                  </div>
                  <button onClick={submitCode} className={`okx-redeem-button`}>
                    <p className="ab okx-redeem-text">Check</p>
                  </button>
                </div>
                <p
                  className={`okx-validation-label ${
                    errorMessage && "okx-validation-label-visible"
                  }`}
                >
                  Invalid code
                </p>
              </div>
            )}
            {currentTask?.is_done ? (
              <button
                onClick={handleOpenLink}
                className={`app-modal-content-button`}
              >
                {currentTask?.btnLabel}
              </button>
            ) : (
              <button
                onClick={handleOpenExternalLink}
                className="app-modal-content-button"
              >
                Watch The W-Space
              </button>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
