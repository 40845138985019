import React from "react";

import { TonConnectUI } from "@tonconnect/ui-react";
import { Stack } from "@mui/material";
import { Address } from "@ton/core";

import { TKey } from "../../../models";
import { truncateString } from "../../../../wallet/utils";
import { IPendingOrder } from "../../../../feature/users/models";
import { ECurrentPurchaseType } from "../models";

import { ActionButton } from "./action-button";

const starsIcon = require("./img/stars-icon.png") as string;

interface IProps {
  currentKey: TKey | null;
  tonConnectUI: TonConnectUI;
  address?: string;
  onDisconnect: () => Promise<void>;
  onOpenCheckTransaction: () => void;
  onBuyKeys: () => void;
  openedStarsOrders?: IPendingOrder[];
  openedTonOrders?: IPendingOrder[];
  onBuyKeyBuyStars: () => void;
  currentPurchaseType: ECurrentPurchaseType | null;
}

// images
const editIcon = require("./img/edit-icon.png") as string;

export const ActionButtons: React.FC<IProps> = ({
  currentKey,
  tonConnectUI,
  address,
  onDisconnect,
  onOpenCheckTransaction,
  onBuyKeys,
  openedStarsOrders,
  openedTonOrders,
  onBuyKeyBuyStars,
}) => {
  return currentKey ? (
    <Stack
      flexDirection="column"
      gap={2}
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="48px"
    >
      {address ? (
        <div className="connected-address">
          <p className="white-color paPassAddress">
            Connected address:{" "}
            {truncateString(
              Address.parse(address).toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={onDisconnect} />
        </div>
      ) : null}
      <Stack width="100%" flexDirection="row" gap={2}>
        {!tonConnectUI.connected && !openedStarsOrders?.length ? (
          <ActionButton
            onClick={() => tonConnectUI.openModal()}
            label={`${currentKey.ton} TON`}
            disableButton={!!openedTonOrders?.length}
          />
        ) : null}
        {tonConnectUI.connected && !openedStarsOrders?.length ? (
          <ActionButton
            onClick={onBuyKeys}
            label={`${currentKey.ton} TON`}
            disableButton={!!openedTonOrders?.length}
          />
        ) : null}
        {openedTonOrders?.length || tonConnectUI.connected ? (
          <ActionButton
            fontSize="16px"
            label="Check order"
            onClick={onOpenCheckTransaction}
            disableButton={!openedTonOrders?.length}
          />
        ) : null}
        {!tonConnectUI.connected && !openedTonOrders?.length ? (
          <ActionButton
            onClick={onBuyKeyBuyStars}
            label={
              <Stack flexDirection="row" alignItems="center" gap={0.5}>
                {" "}
                <img src={starsIcon} alt="" /> {`${currentKey.stars}`}
              </Stack>
            }
            disableButton={!!openedStarsOrders?.length}
          />
        ) : null}
        {openedStarsOrders?.length ? (
          <ActionButton
            fontSize="16px"
            label="Check order"
            onClick={onOpenCheckTransaction}
            disableButton={!openedStarsOrders?.length}
          />
        ) : null}
      </Stack>
    </Stack>
  ) : (
    <Stack
      flexDirection="column"
      gap={2}
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="48px"
    ></Stack>
  );
};
