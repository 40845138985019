import React from "react";

import { Stack } from "@mui/material";
import { Benefits } from "./benefits-item";
import { EChestNames } from "../../../models";

const wcoinIcon = require("../../img/wcoin-icon.png") as string;
const batteryIcon = require("../../img/battery-icon.png") as string;
const lackyDiceIcon = require("../../img/lacky-dice-icon.png") as string;
const waiIcon = require("../../img/wai-icon.png") as string;
const keyIcon = require("../../img/key-icon.png") as string;
const galaxyLiteIcon = require("../../img/galaxy-lite-icon.png") as string;
const galaxyCompletedIcon = require("../../img/galaxy-icon.png") as string;

interface IProps {
  type?: EChestNames;
}

export const BenefitList: React.FC<IProps> = ({ type }) => {
  return (
    <Stack
      style={{ flex: 1 }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <Benefits src={wcoinIcon} alt="" width="50px" height="50px" />
      {type !== EChestNames.STARTER && (
        <Benefits src={batteryIcon} alt="" width="40.4px" height="44.86px" />
      )}
      {type !== EChestNames.STARTER && (
        <Benefits src={lackyDiceIcon} alt="" width="44.4px" height="45.25px" />
      )}
      {type === EChestNames.TREASURE || type === EChestNames.WCOSMIC ? (
        <Benefits src={waiIcon} alt="" width="36.49px" height="36.08px" />
      ) : null}
      {type === EChestNames.TREASURE ||
      type === EChestNames.WCOSMIC ||
      type === EChestNames.STARTER ||
      type === EChestNames.GUARDIAN ? (
        <>
          <Benefits src={keyIcon} alt="" width="41.35px" height="46.08px" />
        </>
      ) : null}
      {type === EChestNames.TREASURE ? (
        <Benefits
          src={galaxyLiteIcon}
          alt=""
          width="45.34px"
          height="34.73px"
        />
      ) : null}
      {type === EChestNames.WCOSMIC ? (
        <>
          <Benefits
            src={galaxyCompletedIcon}
            alt=""
            width="45.34px"
            height="34.73px"
          />
        </>
      ) : null}
    </Stack>
  );
};
