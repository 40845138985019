import React, { useMemo, memo } from "react";

// path
import { TAppUser, TClaimState } from "../../../contexts/types";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { AbsoluteSpinner } from "../../../absolute-spinner";

import { Modal } from "./modal";
import { useCampaignApi } from "./hooks";

// styles
import "./index.scss";

// images
const campaignLogo = require("./img/campaign-logo.png") as string;

interface IProps {
  user: TAppUser;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
}

export const AzureGamesCampaign: React.FC<IProps> = memo(
  ({ user, setClaimState }) => {
    const {
      activeTask,
      onModalClose,
      tasksToDisplay,
      allTasks,
      onTaskClick,
      errorMessage,
      setErrorMessage,
      completedTasksCount,
      updateState,
      handleUpdateTask,
      inputCode,
      setInputCode
    } = useCampaignApi(user, setClaimState);

    const renderedTasks = useMemo(
      () =>
        tasksToDisplay.map((task) => (
          <div
            className="collab-list-item"
            onClick={() => onTaskClick(task)}
            key={task.type}
            style={{
              position: "relative",
            }}
          >
            <img
              className="collab-item-icon"
              src={task.icon}
              alt=""
              width="70px"
              height="70px"
            />
            <div className="collab-item-content">
              <div className="task-item-reward">
                <div className="task-item-info-stack">
                  <div className="white-color">{task.title}</div>
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )),
      [tasksToDisplay, onTaskClick]
    );

    return (
      <div
        className="task-wrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <AbsoluteSpinner show={updateState.isLoading} />
        <Modal
          currentTask={activeTask}
          onClose={onModalClose}
          handleUpdateTask={handleUpdateTask}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          inputCode={inputCode}
          setInputCode={setInputCode}
        />
        <div
          className="task-list-main-wrapper bebebe"
          style={{height: 'auto', backdropFilter: 'blur(10px)' }}
        >
          <div className="task-list-wrapper">{renderedTasks}</div>
          <div className="collab-footer">
              <div>
                Tasks completed:{" "}
                <b className="collab-footer-counter ab">
                  {completedTasksCount}/{allTasks}
                </b>
              </div>
          </div>
        </div>
        <img
          style={{ display: "flex", margin: "0 auto", }}
          width="240px"
          src={campaignLogo}
          alt=""
        />
      </div>
    );
  }
);
