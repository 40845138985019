import React from "react";

import {
  CHAIN,
  SendTransactionRequest,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { Address, toNano } from "@ton/ton";
import { beginCell } from "@ton/ton";
import WebApp from "@twa-dev/sdk";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";
import { useUpdateUserExternalApi } from "../../hooks";
import { getCurrentUTCDateTime } from "../../utils";
import { PAY_PASS_MULTIPLIER, PAY_PASS_MULTIPLIER_LIGHT } from "../../consts";
import { LottieAnimationContext } from "../contexts/lottie-animation-context";
import { useGetPayPassGenerateInvoiceMutation, useGetPayPassLiteGenerateInvoiceMutation } from "../feature/users";

import { PAY_PAY_REWARD,PAY_PAY_REWARD_LIGHT, STARS_COMPLETE_PRICE, STARS_LIGHT_PRICE  } from "./consts";
import { EPaymentMethod, EPayPassTab } from "./models";

export const usePayPassApi = () => {
  const [payPassTab, setPayPassTab] = React.useState(EPayPassTab.COMPLETE);

  const isPayPassTabComplete = payPassTab === EPayPassTab.COMPLETE;

  const handlePayPassTabChange = (type: EPayPassTab) => {
    setPayPassTab(type);
  }
  const STARS_AMOUNT = isPayPassTabComplete ? STARS_COMPLETE_PRICE : STARS_LIGHT_PRICE;
  const RECIPIENT_ADDRESS = (isPayPassTabComplete ? process.env.REACT_APP_PAY_PASS : process.env.REACT_APP_PAY_LITE) || '';
  const AMOUNT = (isPayPassTabComplete ? process.env.REACT_APP_PAY_PASS_AMOUNT  : process.env.REACT_APP_PAY_PASS_AMOUNT_LIGHT)  || "";

  // Context
  const { isLoading, user, setClaimState } = React.useContext(UserContext);
  const { setIsVisible } = React.useContext(LottieAnimationContext);
  const [impactOccurred] = useHapticFeedback();

  // Wallets connections
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  // @ts-ignore
  const notAcceptedWallet = wallet?.name === "HOT";
  // @ts-ignore
  const isNetNotAccepted = React.useMemo(
    () => wallet?.account?.chain === CHAIN.TESTNET || notAcceptedWallet,
    [notAcceptedWallet, wallet?.account?.chain]
  );

  // States
  const [showError, setShowError] = React.useState<boolean>(false);
  const [showNetError, setShowNetError] = React.useState<boolean>(false);

  const [showSuccessPayPassModal, setShowSuccessPayPassModal] =
    React.useState<boolean>(false);

  // Update handler
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [getPayPassGenerateInvoice, getPayPassGenerateInvoiceState] =
    useGetPayPassGenerateInvoiceMutation();
    const [getPayPassLiteGenerateInvoice, getPayPassLiteGenerateInvoiceState] =
    useGetPayPassLiteGenerateInvoiceMutation();

  const [isPreparing, setIsPreparing] = React.useState(false);

  // Consts
  const claimState = user?.claim_state;
  const databaseUserId = user?.claim_state?.id;

  const PAYLOAD_BODY = beginCell()
    .storeUint(0, 32)
    .storeStringTail(`payPass: ${databaseUserId || ""}`)
    .endCell();

  const address = wallet?.account?.address;

  const handleDisconnect = async () => {
    try {
      await tonConnectUI.disconnect();
    } catch (e) {
      console.log("Error during disconnect:", e);
    }
  };

  React.useEffect(() => {
    if (isNetNotAccepted) {
      handleDisconnect();
      setShowNetError(true);
    }
  }, [handleDisconnect, isNetNotAccepted]);

  const handleUpdateDb = (boc: string, isStars: boolean = false) => {
    if (isNetNotAccepted) {
      handleDisconnect();
      setShowNetError(true);
    }

    if (isStars ? isStars && claimState : claimState && address) {
      const payPass = isStars
        ? {
          senderAddress: {
            bounceableAddress: 'empty',
            unbounceableAddress: 'empty',
          },
          recipientAddress: 'empty',
          date: getCurrentUTCDateTime(),
          amount: STARS_AMOUNT,
          boc,
          // @ts-ignore
          walletName: 'empty',
          paymentMethod: EPaymentMethod.STARS,
          }
        : {
            senderAddress: {
              bounceableAddress: address,
              unbounceableAddress: Address.parse(address || "").toString({
                bounceable: false,
              }),
            },
            recipientAddress: RECIPIENT_ADDRESS,
            date: getCurrentUTCDateTime(),
            amount: AMOUNT,
            boc,
            // @ts-ignore
            walletName: wallet?.name,
            paymentMethod: EPaymentMethod.TON,
          };

      const boosts = claimState?.boosts
        ? { ...claimState.boosts, [isPayPassTabComplete ? 'payPass' : 'payPassLite']: payPass }
        : { [isPayPassTabComplete ? 'payPass' : 'payPassLite']: payPass };

      const newData = {
        boosts,
        balance: claimState!.balance + (isPayPassTabComplete ? PAY_PAY_REWARD : PAY_PAY_REWARD_LIGHT) ,
      };

      updateUserExternalApi({ ...newData })
        .then(() => {
          // @ts-ignore
          setClaimState?.((prevState) => ({
            ...(prevState || {}),
            ...newData,
            payPassMultiplier: isPayPassTabComplete ? PAY_PASS_MULTIPLIER : PAY_PASS_MULTIPLIER_LIGHT,
          }));

          setShowSuccessPayPassModal(true);
          impactOccurred("heavy");
        })
        .catch((e: any) => {
          console.warn(e);
          impactOccurred("heavy");
        })
        .finally(() => {
          setIsPreparing(false);
          impactOccurred("heavy");
        });
    }
  };

  const handleGetPayPassGenerateInvoice = async () => {
    if (claimState) {
      if (payPassTab === EPayPassTab.COMPLETE) {
        try {
          const result = await getPayPassGenerateInvoice().unwrap();
  
          if (result?.result) {
            WebApp.openInvoice(result?.result, (status) => {
              if (status === "paid") {
                handleUpdateDb("empty boc", true);
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const result = await getPayPassLiteGenerateInvoice().unwrap();
          
          if (result?.result) {
            WebApp.openInvoice(result?.result, (status) => {
              if (status === "paid") {
                handleUpdateDb("empty boc", true);
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleSentTransaction = async () => {
    if (!wallet || !wallet.account?.address) {
      console.error("Wallet not connected");
      return;
    }

    if (claimState && address) {
      const transaction: SendTransactionRequest = {
        validUntil: Math.floor(Date.now() / 1000) + 3600,
        messages: [
          {
            address: RECIPIENT_ADDRESS  || '',
            amount: toNano(AMOUNT).toString(),
            payload: PAYLOAD_BODY.toBoc().toString("base64"),
          },
        ],
      };
  
      try {
        if (claimState && address) {
          setIsPreparing(true);
          const response = await tonConnectUI.sendTransaction(transaction);
  
          if (response) {
            handleUpdateDb(response?.boc || "empty boc");
          }
        }
      } catch (error) {
        setIsPreparing(false);
        setShowError(true);
      }
    }
  };

  const handleCloseSuccessPayPassModal = () => {
    impactOccurred("heavy");
    setShowSuccessPayPassModal(false);
    setIsVisible?.(true);
  };

  const isPayPassPurchased = !!user?.claim_state?.boosts?.payPass || !!user?.claim_state?.boosts?.payPassLite;
  const payPassPurchasedType =user?.claim_state?.boosts?.payPass ? EPayPassTab.COMPLETE : user?.claim_state?.boosts?.payPassLite ? EPayPassTab.LIGHT : null;


  React.useEffect(() => {
    if (isPayPassPurchased && payPassPurchasedType) {
      setPayPassTab(payPassPurchasedType);
    }
  } ,[isPayPassPurchased, payPassPurchasedType])

  return {
    isWalletConnected: tonConnectUI.connected,
    address: wallet?.account?.address,
    isFetching:
      updateUserState.isFetching || getPayPassGenerateInvoiceState.isLoading || getPayPassLiteGenerateInvoiceState.isLoading,
    disabled: updateUserState.isFetching || isPreparing,
    isPayPassUpdating: updateUserState.isLoading,
    onDisconnect: handleDisconnect,
    onSentTransaction: handleSentTransaction,
    onGetPayPassGenerateInvoice: handleGetPayPassGenerateInvoice,
    showError,
    setShowError,
    isLoading,
    showSuccessPayPassModal,
    onCloseSuccessPayPassModal: handleCloseSuccessPayPassModal,
    isPayPassPurchased,
    payPassPurchasedType,
    showNetError,
    setShowNetError,
    payPassTab,
    onPayPassTabChange: handlePayPassTabChange,
  };
};
