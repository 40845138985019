import React, { memo } from "react";

import { Stack } from "@mui/material";

import { TemplatePages } from "../template-pages";

import { useOnboardingApi, usePageChestApi } from "./hooks";
import { OnboardingModal } from "./onboarding-modal";
import { BalanceAndShop } from "./balance-and-shop";
import { Header } from "./header";
import { ChestList } from "./chest-list";
import { Modals } from "./modals";
import "./index.scss";
import { TChestsHistory } from "./models";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { Spinner } from "../spinner";
import { ROUTES } from "../routers";

const bg = require("./img/bg.png") as string;

export const PageChests: React.FC = memo(() => {
  const { showOnboarding, onShowOnboarding } = useOnboardingApi();
  const {
    chests,
    isLoading,
    isFetching,
    currentChest,
    modalType,
    onModalTypeClick,
    onChestClick,
    onChestsModalFormOpenPage,
    onOpenChest,
    currentReward,
    onCloseModal,
    isUserLoading,
    blockCollentBtn,
    onRefecthUserData,
  } = usePageChestApi();

  const balance = Number(chests?.data?.keys_balance?.amount || 0);
  const history = chests?.data?.open_chests as TChestsHistory[] | undefined;

  return (
    <TemplatePages isLoading={isLoading} isFetching={isUserLoading} bg={bg}>
      <BackButton onClick={onRefecthUserData} />
      <Spinner isLoading={!!isFetching} />
      <Stack
        padding={3}
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        sx={{ overflow: "auto" }}
      >
        <Modals
          modalType={modalType}
          currentChest={currentChest}
          onChestsModalFormOpenPage={onChestsModalFormOpenPage}
          balance={balance}
          onModalTypeClick={onModalTypeClick}
          onOpenChest={onOpenChest}
          currentReward={currentReward}
          history={history}
          onCloseModal={onCloseModal}
          blockCollentBtn={blockCollentBtn}
        />
        <OnboardingModal show={showOnboarding} onClose={onShowOnboarding} />
        {showOnboarding ? null : (
          <Stack height="100%" display="flex" flexDirection="column" gap={2}>
            <Header onShowOnboarding={onShowOnboarding} />
            <BalanceAndShop
              onModalTypeClick={onModalTypeClick}
              balance={balance}
            />
            <ChestList
              currentChest={currentChest}
              onChestClick={onChestClick}
              balance={balance}
            />
          </Stack>
        )}
      </Stack>
    </TemplatePages>
  );
});
