import React from "react";

import { ListWrapper } from "../list-wrapper";
import { TMates } from "../../feature/users/models";

import "./index.scss";
import { MatesListItem } from "./mates-list-item";

interface IProps {
  mates?: TMates;
  onGetClaimAll: () => void;
  handleRefetchUsers: () => void;
}

export const MatesList: React.FC<IProps> = ({
  mates,
  onGetClaimAll,
  handleRefetchUsers,
}) => {
  return (
    <div className="mates-list-root">
      <ListWrapper
        handleRefetchUsers={handleRefetchUsers}
        mates={mates}
        onGetClaimAll={onGetClaimAll}
      >
        <div className="mates-list">
          {mates?.top_reward_mates?.map((mate, index) => (
            <MatesListItem key={index} mate={mate} />
          ))}
        </div>
      </ListWrapper>
    </div>
  );
};
