import { ERewardType } from "../../models";

const wcoinIcon = require("./img/coin-icon.png") as string;
const batteryIcon = require("./img/battery-icon.png") as string;
const diceIcon = require("./img/dice-icon.png") as string;
const waiIcon = require("./img/wai-icon.png") as string;
const keysIcon = require("./img/key-icon.png") as string;
const payPassLiteIcon = require("./img/galaxy-icon-lite.png") as string;
const payPassCompleteIcon = require("./img/galaxy-icon-completed.png") as string;


export const REWARD_ICONS: Record<ERewardType, {icon: string, text: string, withValue: boolean, width?: string, height?: string}> = {
    [ERewardType.wcoin]: {
        "icon": wcoinIcon,
        "text": "W-Coin",
        withValue: true,
        height: '122px',
        width: '122px',
    },
    [ERewardType.battery]: {
        "icon": batteryIcon,
        "text": "Battery",
        withValue: true,
        height: '122px',
        width: '105px',
    },
    [ERewardType.dice]: {
        "icon": diceIcon,
        "text": "Lucky Dice",
        withValue: true,
        height: '122px',
        width: '107px',
    },
    [ERewardType.wai]: {
        "icon": waiIcon,
        "text": "WAI",
        withValue: true,
        height: '122px',
        width: '122px',
    },
    [ERewardType.keys]: {
        "icon": keysIcon,
        "text": "Keys",
        withValue: true,
        height: '122px',
        width: '105px',
    },
    [ERewardType["pay-pass-lite"]]: {
        "icon": payPassLiteIcon,
        "text": "W-Galaxy Lite",
        withValue: false,
        height: '80px',
        width: '122px',
    },
    [ERewardType["pay-pass-complete"]]: {
        "icon": payPassCompleteIcon,
        "text": "W-Galaxy Complete",
        withValue: false,
        height: '80px',
        width: '122px',
    },
}