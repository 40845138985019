import React from "react";

import "../index.scss";

import { CSSTransition } from "react-transition-group";
import {
  UPGRADES,
  MULTIPLIER_UPGRADES,
  WORKER_UPGRADES,
} from "../../page-boosters/boosters/consts";
import { EBooster } from "../../page-boosters/boosters/types";
import { ModalBoosterContent } from "./modal-booster-content";
import { isMaxLevel } from "../../page-boosters/utils";
import { TClaimState } from "../../contexts/types";
import { DAILY_BOOSTERS_TIMES } from "../../contexts/consts";
const tapIcon =
  require("../../page-boosters/boosters/main-boosters/boosters-items/img/tap.png") as string;
const chargerIcon =
  require("../../page-boosters/boosters/main-boosters/boosters-items/img/charger.png") as string;
const batteryIcons = require("../../../img/new-icons/battery.png") as string;

interface IProps {
  open: EBooster | null;
  onClose: (event: React.SyntheticEvent) => void;
  onUpdateBoosters: (type: EBooster, boosts?: { diceValue?: number }) => void;
  multiplierLevel: number;
  chargerLevel: number;
  maximizerLevel: number;
  claimState?: TClaimState;
  payPassMultiplier?: number;
}

export const BoostersModal: React.FC<IProps> = ({
  open,
  onClose,
  onUpdateBoosters,
  multiplierLevel,
  chargerLevel,
  maximizerLevel,
  claimState,
  payPassMultiplier,
}) => {
  const boosterModalContent = React.useMemo(() => {
    const { TAP_UPGRADES, CHARGER_UPGRADES, MAXIMIZER_UPGRADES } = UPGRADES;

    const isDisabled = (price: number, balance: number = 0) => {
      return balance - price < 0;
    };

    switch (open) {
      case EBooster.FULL_BATTERY:
        return (
          <ModalBoosterContent
            onClick={() => {
              onUpdateBoosters(EBooster.FULL_BATTERY);
            }}
            imageIcon={batteryIcons}
            title="Full Battery"
            description="Instant battery charging up to 100%"
            upgrade={`${
              DAILY_BOOSTERS_TIMES * (payPassMultiplier as number)
            } times a day`}
            price="Free"
            buttonLabel="Claim"
            onClose={onClose}
          />
        );
      case EBooster.LUCKY_DICE:
        return (
          <ModalBoosterContent
            onClick={(value?: number) => {
              if (value) {
                onUpdateBoosters(EBooster.LUCKY_DICE, { diceValue: value });
              }
            }}
            icon="🎲"
            title="Lucky Dice"
            description="Try your luck and get up to 20x for tapping for 10 seconds"
            upgrade={`${`${
              DAILY_BOOSTERS_TIMES * (payPassMultiplier as number)
            } times a day`}`}
            price="Free"
            buttonLabel="Roll!"
            isSecondStep={true}
            onClose={onClose}
          />
        );
      case EBooster.TAP:
        return (
          <ModalBoosterContent
            onClick={() => onUpdateBoosters(EBooster.TAP)}
            imageIcon={tapIcon}
            title="Tap"
            description="Increase amount of W-coin you can earn per one tap"
            upgrade={`+1 per tap for each level`}
            price={TAP_UPGRADES[multiplierLevel]?.price}
            level={multiplierLevel}
            buttonLabel="Upgrade"
            disabled={isDisabled(
              TAP_UPGRADES[multiplierLevel]?.price,
              claimState?.balance
            )}
            isMaxLevel={isMaxLevel(
              multiplierLevel,
              Object.values(TAP_UPGRADES).length
            )}
            onClose={onClose}
          />
        );
      case EBooster.CHARGER:
        return (
          <ModalBoosterContent
            onClick={() => onUpdateBoosters(EBooster.CHARGER)}
            imageIcon={chargerIcon}
            title="Charger"
            description="Increase the charging speed of the tap scale (+1⚡)"
            upgrade={
              <>Speed: {`${CHARGER_UPGRADES[chargerLevel]?.upgrade}⚡ / sec`}</>
            }
            price={CHARGER_UPGRADES[chargerLevel]?.price}
            level={chargerLevel}
            buttonLabel="Upgrade"
            disabled={isDisabled(
              CHARGER_UPGRADES[chargerLevel]?.price,
              claimState?.balance
            )}
            isMaxLevel={isMaxLevel(
              chargerLevel,
              Object.values(CHARGER_UPGRADES).length
            )}
            onClose={onClose}
          />
        );

      case EBooster.MAXIMIZER:
        return (
          <ModalBoosterContent
            onClick={() => onUpdateBoosters(EBooster.MAXIMIZER)}
            icon="⛽"
            title="Maximizer"
            description="Increase your power limit, so you can tap more per session"
            upgrade={<>+500⚡ for each level</>}
            price={MAXIMIZER_UPGRADES[maximizerLevel]?.price}
            level={maximizerLevel}
            buttonLabel="Upgrade"
            disabled={isDisabled(
              MAXIMIZER_UPGRADES[maximizerLevel]?.price,
              claimState?.balance
            )}
            isMaxLevel={isMaxLevel(
              maximizerLevel,
              Object.values(MAXIMIZER_UPGRADES).length
            )}
            onClose={onClose}
          />
        );

      case EBooster.MULTIPLIER:
        return (
          <ModalBoosterContent
            onClick={() => onUpdateBoosters(EBooster.MULTIPLIER)}
            icon="🎰"
            title="Multiplier"
            description="Get extra tap x100 at random moment"
            upgrade="One-time purchase"
            price={MULTIPLIER_UPGRADES.price}
            buttonLabel="Purchase"
            isMaxLevel={false}
            onClose={onClose}
            disabled={isDisabled(
              MULTIPLIER_UPGRADES.price,
              claimState?.balance
            )}
          />
        );
      case EBooster.WORKER:
        return (
          <ModalBoosterContent
            onClick={() => onUpdateBoosters(EBooster.WORKER)}
            icon="🤖"
            title="Worker"
            description="Mines coins while you’re sleeping."
            upgrade="One-time purchase"
            price={WORKER_UPGRADES.price}
            buttonLabel="Purchase"
            isMaxLevel={false}
            onClose={onClose}
            disabled={isDisabled(WORKER_UPGRADES.price, claimState?.balance)}
          />
        );
      default:
        return null;
    }
  }, [
    open,
    onClose,
    multiplierLevel,
    claimState?.balance,
    chargerLevel,
    maximizerLevel,
    onUpdateBoosters,
  ]);

  return (
    <CSSTransition in={!!open} timeout={400} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">{boosterModalContent}</div>
    </CSSTransition>
  );
};
