import React from "react";

import { ETab, ETask, ITask } from "../type";
import { AzureGamesCampaign, OkxCampaign } from "../campaigns";
import { TaskList } from "../task-list";
import { TAppUser, TClaimState } from "../../contexts/types";
import { TRune } from "../../hooks/rune/models";
import { TaskCounterAndDescription } from "../task-counter-and-description";
import { ToggleGroup } from "../toggle-group";
import { BitgetWalletCampaign } from "../campaigns/bitget-wallet-campaign";

interface IProps {
  activeTab: ETab;
  user: TAppUser;
  setClaimState:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined;
  can_update_ads: number;
  WALLET_TASK: ITask;
  THREE_FRIENDS_TASK: ITask;
  activeTasksList: {
    [key: string]: ITask[];
  };
  onClaimTask: (ct: ETask | string | null) => Promise<void>;
  onChangeCurrentIndexTask: (ct: ETask | string | null) => void;
  readyToClaim: {};
  onSetShowVerifyModal: (value: boolean) => void;
  onSetShowShareModal: () => void;
  onshowAdvert: () => void;
  completedTasksList: {
    [key: string]: ITask[];
  };
  isCompletedTasksListEmpty: boolean;
  onSetRuneStep: ((runeStep: TRune) => void) | undefined;
  onActiveTaskChange: (tab: ETab) => void;
  payPassMultiplier?: number;
  onCheckCodeModal: (value: boolean) => void;
}

export const Content: React.FC<IProps> = ({
  activeTab,
  user,
  setClaimState,
  can_update_ads,
  WALLET_TASK,
  THREE_FRIENDS_TASK,
  activeTasksList,
  onClaimTask,
  onChangeCurrentIndexTask,
  readyToClaim,
  onSetShowVerifyModal,
  onSetShowShareModal,
  onshowAdvert,
  completedTasksList,
  isCompletedTasksListEmpty,
  onSetRuneStep,
  onActiveTaskChange,
  payPassMultiplier,
  onCheckCodeModal,
}) => {
  const isCampaign = activeTab === ETab.OKX;
  const isBitgetWalletCampaign = activeTab === ETab.BITGET_WALLET;

  const getContent = () => {
    switch (activeTab) {
      case ETab.BITGET_WALLET:
        return <BitgetWalletCampaign  user={user} setClaimState={setClaimState} />;

        case ETab.AZURE:
          return <AzureGamesCampaign  user={user} setClaimState={setClaimState} />;

      case ETab.OKX:
        return (
          <OkxCampaign
            user={user}
            setClaimState={setClaimState}
          />
        );

      case ETab.ACTIVE:
        return (
          <div className="task-wrapper">
            <div className="task-list-main-wrapper bebebe">
              <div className="task-list-wrapper">
                <TaskList
                  can_update_ads={can_update_ads}
                  payPassMultiplier={payPassMultiplier}
                  walletTask={WALLET_TASK}
                  threeFriendsTask={THREE_FRIENDS_TASK}
                  taskList={activeTasksList}
                  onClaimTask={onClaimTask}
                  onChangeCurrentIndexTask={onChangeCurrentIndexTask}
                  readyToClaim={readyToClaim}
                  onSetShowVerifyModal={onSetShowVerifyModal}
                  onSetShowShareModal={onSetShowShareModal}
                  showAdvert={onshowAdvert}
                  onCheckCodeModal={onCheckCodeModal}
                />
              </div>
            </div>
          </div>
        );

      case ETab.COMPLETED:
        return (
          <div className="task-wrapper">
            <div className="task-list-main-wrapper bebebe">
              <div className="task-list-wrapper">
                <TaskList
                  payPassMultiplier={payPassMultiplier}
                  walletTask={WALLET_TASK}
                  threeFriendsTask={THREE_FRIENDS_TASK}
                  taskList={completedTasksList}
                  onClaimTask={onClaimTask}
                  onChangeCurrentIndexTask={onChangeCurrentIndexTask}
                  readyToClaim={readyToClaim}
                  isEmpty={isCompletedTasksListEmpty}
                  user={user}
                  onSetRuneStep={onSetRuneStep}
                  isCompleted={activeTab === ETab.COMPLETED}
                />
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="content mates-content task-content">
      <TaskCounterAndDescription isBitgetWalletCampaign={isBitgetWalletCampaign} user={user} isCampaign={isCampaign} />
      <div className="task-root-wrapper">
        <ToggleGroup activeTab={activeTab} onChange={onActiveTaskChange} isCampaign={isBitgetWalletCampaign} />
        {getContent()}
      </div>
    </div>
  );
};
