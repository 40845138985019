import React from "react";

import { Typography, Stack, Button, Box } from "@mui/material";

import { ERewardType, TChest } from "../../models";
import { IUpdateChestsOpen } from "../../../feature/users/models";
import { REWARD_ICONS } from "./consts";
import { formatValues } from "../../../../utils";

interface IProps {
  currentChest: TChest | null;
  onChestsModalFormOpenPage: () => void;
  currentReward: IUpdateChestsOpen | null;
  blockCollentBtn: boolean;
}

export const RewardContent: React.FC<IProps> = ({
  currentChest,
  onChestsModalFormOpenPage,
  currentReward,
  blockCollentBtn,
}) => {
  const currentRewardType = currentReward?.data?.reward;

  return (
    <Stack
      className="app-modal-content task-modal-content"
      style={{ justifyContent: "space-between" }}
      overflow="auto"
      gap={1}
    >
      <Typography
        className="ab"
        style={{ fontSize: "35px", color: "#D6D6E7" }}
        variant="h4"
        component="h2"
      >
        {currentChest?.name}
      </Typography>

      <Stack
        justifyContent="center"
        gap={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          width="122px"
          height="122px"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ margin: "0 auto" }}
            width={
              currentRewardType
                ? REWARD_ICONS[currentRewardType.type]?.width
                : undefined
            }
            height={
              currentRewardType
                ? REWARD_ICONS[currentRewardType.type]?.height
                : undefined
            }
            src={
              currentRewardType
                ? REWARD_ICONS[currentRewardType.type].icon
                : undefined
            }
            alt=""
          />
        </Box>

        <Stack gap={1} justifyContent="center">
          <Typography
            className="ab"
            color="#fff"
            style={{ fontSize: "26px", lineHeight: "1", textAlign: "center" }}
          >
            {currentRewardType
              ? REWARD_ICONS[currentRewardType.type].text
              : undefined}
          </Typography>

          <Typography
            className="ab"
            color="#fff"
            style={{ fontSize: "26px", lineHeight: "1", textAlign: "center" }}
          >
            {currentRewardType && REWARD_ICONS[currentRewardType.type].withValue
              ? formatValues(currentRewardType.value)
              : undefined}
          </Typography>
        </Stack>
      </Stack>

      <Box width="257px" height="240.86px">
        <img
          width="257px"
          height="240.86px"
          src={currentChest?.iconOpened}
          alt=""
        />
      </Box>
      <Button
        onClick={onChestsModalFormOpenPage}
        sx={{
          background: "linear-gradient(to right, #8254BD 3%, #C333E7 100%)",
          width: "100%",
          borderRadius: "22px",
        }}
      >
        <Typography
          className={`ab ${blockCollentBtn ? "btn-disabled" : ""}`}
          sx={{ color: "#fff", fontSize: "24px", textTransform: "none" }}
        >
          Collect
        </Typography>
      </Button>
    </Stack>
  );
};
