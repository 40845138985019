import React from "react";

import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import { formatStatisticsValue } from "../../../../utils";
import { ZoomInAnimation } from "../../../page-animation";
import { INVITE_MATE_URL } from "../../../page-mates/consts";

import "./index.scss";

// images
const experts = require("./img/experts.png") as string;
const wai = require("./img/wai.png") as string;

interface IProps {
  balance: number;
  matesBalance: number;
  isFetching: boolean;
}

export const Balance: React.FC<IProps> = ({
  balance,
  matesBalance,
  isFetching,
}) => {
  const getWaiBalanceItem = (
    title: string,
    balance: number,
    isExperts: boolean
  ) => {
    return (
      <div
        className="wai-balance-item"
        style={{ alignItems: isExperts ? "start" : "end" }}
      >
        <p className="gray-color wai-balance-text">{title}</p>
        <div className="">
          <ZoomInAnimation>
            {isFetching ? (
              <></>
            ) : (
              <div style={{ display: "flex" }}>
                <p
                  className={`statistics-item-value balance claim-balance ab stats-value wai-balance ${
                    isExperts ? "experts-color" : "wai-color"
                  }`}
                >
                  <img
                    width={isExperts ? "40px" : "26px"}
                    src={isExperts ? experts : wai}
                    alt=""
                  />
                  {formatStatisticsValue(balance, 1)}
                </p>
                {isExperts && (
                  <p className="ab wai-invite-mate addMatesImg">+</p>
                )}
              </div>
            )}
          </ZoomInAnimation>
        </div>
      </div>
    );
  };

  const webApp = useWebApp();

  const handleInviteMate = () => {
    webApp.openTelegramLink(INVITE_MATE_URL);
    webApp.close();
  };

  return (
    <div className="wai-balance-root">
      <div className="wai-balance-root" onClick={handleInviteMate}>
        {getWaiBalanceItem("Experts:", matesBalance, true)}
      </div>
      {getWaiBalanceItem("$WAI Balance:", balance, false)}
    </div>
  );
};
