import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import { TTelegramGroupVerify } from "../../contexts/types";
import { useOpenTelegramLink } from "../../hooks";
import { useVerifyTaskMutation } from "../../feature/users";

import { ITask } from "../type";
import { Spinner } from "../../spinner";

interface IProps {
  currentTask: ITask;
  onClaim?: () => void;
  onTelegramGroupVerify?: TTelegramGroupVerify;
}

const PLAY_8_GAMES_TASK__STATUS_COMPLETED = "Play8Games task is completed.";
const JOIN_TELEGRAM_TASK_STATUS_COMPLETED =
  "JoinTelegramGroup task is completed.";

const COMPLETED_STATUSES = [
  PLAY_8_GAMES_TASK__STATUS_COMPLETED,
  JOIN_TELEGRAM_TASK_STATUS_COMPLETED,
];

export const ExternalVerifyButton: React.FC<IProps> = ({
  currentTask,
  onClaim,
}) => {
  const webApp = useWebApp();

  const { onOpenTelegramLink } = useOpenTelegramLink();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTryVerified, setIsTryVerified] = React.useState<
    boolean | undefined
  >();
  const [impactOccurred] = useHapticFeedback();

  const [verifyTask, verifyTaskState] = useVerifyTaskMutation();

  // @ts-ignore
  const telegramUser = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  const handleVerify = () => {
    if (telegramUser && currentTask.verifyExternalTaskName) {
      try {
        verifyTask({
          telegramUser: String(telegramUser),
          taskName: currentTask.verifyExternalTaskName,
        })
          .unwrap()
          .then((res) => {
            if (
              res.status === "success" &&
              COMPLETED_STATUSES.includes(res.message)
            ) {
              setIsVerified(true);
              setIsTryVerified(undefined);
            } else {
              setIsVerified(false);
              setIsTryVerified(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });

        impactOccurred("heavy");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleTryVerifiedContent = () => {
    if (typeof isTryVerified === "undefined") {
      return null;
    } else if (isTryVerified === true) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#fff",
            position: "absolute",
            top: "-36%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <p>Task not completed.</p>
          <p>Try again</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const onOpenExternalTelegramEntity = (link: string | null) => {
    webApp.openTelegramLink(link);
  };

  const handleOpenLink = React.useCallback(() => {
    return currentTask?.is_done
      ? onOpenTelegramLink()
      : onOpenExternalTelegramEntity(currentTask.link);
  }, [
    currentTask?.is_done,
    currentTask.link,
    onOpenExternalTelegramEntity,
    onOpenTelegramLink,
  ]);

  return (
    <div
      className="task-modal-button-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative",
      }}
    >
      <Spinner isLoading={verifyTaskState.isLoading} />
      {handleTryVerifiedContent()}
      {!currentTask.is_done && (
        <button
          onClick={isVerified ? onClaim : handleVerify}
          className={`app-modal-content-button ${
            currentTask.is_done ? "btn-disabled" : ""
          }`}
        >
          {isVerified ? "Claim" : "Verify"}
        </button>
      )}

      {!currentTask.is_done && (
        <div className="task-modal-button-wrapper">
          <button
            onClick={handleOpenLink}
            className={`app-modal-content-button`}
          >
            {currentTask?.btnLabel}
          </button>
        </div>
      )}
    </div>
  );
};
