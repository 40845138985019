import React from "react";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CSSTransition } from "react-transition-group";

import { EModalType, TChest, TChestsHistory } from "../models";

import { ChestContent } from "./chest-content";
import { KeyShopContent } from "./key-shop-content";
import { HistoryContent } from "./history-content";
import { RewardContent } from "./reward-content";
import { IUpdateChestsOpen } from "../../feature/users/models";

interface IProps {
  currentChest: TChest | null;
  onCloseModal: () => void;
  onChestsModalFormOpenPage: () => void;
  modalType: EModalType | null;
  balance?: number;
  onModalTypeClick: (value: EModalType | null) => void;
  onOpenChest: () => void;
  currentReward: IUpdateChestsOpen | null;
  history?: TChestsHistory[];
  blockCollentBtn: boolean;
}

export const Modals: React.FC<IProps> = ({
  currentChest,
  onChestsModalFormOpenPage,
  modalType,
  balance,
  onModalTypeClick,
  onOpenChest,
  currentReward,
  onCloseModal,
  blockCollentBtn,
  history,
}) => {
  const getContent = () => {
    switch (modalType) {
      case EModalType.DETAILS:
        return (
          <ChestContent
            currentChest={currentChest}
            balance={balance}
            onModalTypeClick={onModalTypeClick}
            onOpenChest={onOpenChest}
          />
        );

      case EModalType.KEY_SHOP:
      case EModalType.CHECK_STANSACTION:
        return <KeyShopContent onModalTypeClick={onModalTypeClick} />;

      case EModalType.HISTORY:
        return <HistoryContent history={history} />;

      case EModalType.REWARD:
        return (
          <RewardContent
            blockCollentBtn={blockCollentBtn}
            currentChest={currentChest}
            onChestsModalFormOpenPage={onChestsModalFormOpenPage}
            currentReward={currentReward}
          />
        );

      default:
        return null;
    }
  };

  const show = !!currentChest || !!modalType;

  return (
    <>
      {!!show && (
        <div className={`overlay app-modal-root ${show ? "blur" : ""}`}></div>
      )}
      <CSSTransition in={show} timeout={2500} classNames="fade" unmountOnExit>
        <div
          className="overlay app-modal-root"
          style={{ justifyContent: "unset" }}
        >
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            style={{
              paddingTop: "25px",
              height:
                modalType === EModalType.DETAILS ||
                modalType === EModalType.REWARD
                  ? "80%"
                  : "100%",
              background:
                "linear-gradient(90deg, rgba(37, 19, 60, 0.9) 3%, rgba(79, 20, 93, 0.9) 100%)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {modalType !== EModalType.CHECK_STANSACTION && (
              <IconButton
                onClick={onCloseModal}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "#fff",
                }}
              >
                <CloseIcon />
              </IconButton>
            )}

            {getContent()}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
