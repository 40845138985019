import { useUpdateUserExternalApiMutation } from "./components/feature/users";
import { TUpdateClientArgs } from "./components/feature/users/models";
import { encryptData } from "./utils";

export const useUpdateUserExternalApi = () => {
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApiMutation();

  async function updateUserAsync(data: TUpdateClientArgs) {
    const result = await encryptData(data);

    if (result) {
      const { encryptedData, encryptedSymmetricKey, iv, authTag } = result;
      return updateUserExternalApi({
        encryptedData,
        encryptedSymmetricKey,
        iv,
        data,
        authTag,
      }).unwrap();
    }
  }

  return [updateUserAsync, updateUserState as any];
};

export const useShowContentApi = () => {
  // @ts-ignore
  const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || "";

  const canShowContent = () => {
    const USER_IDS = process.env.REACT_APP_TEST_USER_IDS;

    if (USER_IDS && id) {
      return USER_IDS.split(", ").includes(String(id));
    }

    return false;
  };

  return {
    canShowContent: canShowContent(),
  };
};

export const useShowContentAzurApi = () => {
  // @ts-ignore
  const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || "";

  const canShowContent = () => {
    const USER_IDS = process.env.REACT_APP_TEST_USER_AZURE_IDS;

    if (USER_IDS && id) {
      return USER_IDS.split(", ").includes(String(id));
    }

    return false;
  };

  return {
    canShowContent: canShowContent(),
  };
};

export const useShowContentBitGetApi = () => {
  // @ts-ignore
  const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || "";

  const canShowContent = () => {
    const USER_IDS = process.env.REACT_APP_TEST_USER_IDS_BITGET;

    if (USER_IDS && id) {
      return USER_IDS.split(", ").includes(String(id));
    }

    return false;
  };

  return {
    canShowContent: canShowContent(),
  };
};



