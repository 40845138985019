import React from "react";

import { SmallCoin } from "../../../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../../../consts";
import { formatValues } from "../../../../../../utils";
import { WSkinsModal } from "../../../../../app-modal/wskins-modal";
import { UserContext } from "../../../../../contexts/user-context";
import { ZoomInAnimation } from "../../../../../page-animation";
import { useUpdateUserExternalApi } from "../../../../../../hooks";
import { ERune, TRune } from "../../../../../hooks/rune/models";
import { TSkins, TTask } from "../../../../../feature/users/models";

import { ESKins, TWSkinsItems } from "./types";
import { Oval } from "react-loader-spinner";

// images
const skinsRetroCoinIcon = require("./img/skins_retro_coin.svg")
  .default as string;
const taskSkinsDefaultIcon = require("./img/skins_default.png") as string;
const taskPlayShurikenIcon = require("./img/skins_shuriken.png") as string;
const taskPlayCatizenIcon = require("./img/skins_catizen.svg")
  .default as string;
const taskDotIcon = require("./img/skins_dot.png") as string;
const runeIcon = require("./img/rune.png") as string;
const tonIcon = require("./img/ton.png") as string;
const ethIcon = require("./img/eth.png") as string;
const solIcon = require("./img/sol.png") as string;
const trustIcon = require("./img/skins_trust.png") as string;
const durovIcon = require("./img/free_durov_preview.png") as string;
const catsIcon = require("./img/catsPreview.png") as string;

interface IProps {
  runeType: ERune;
  counter: {
    start: number;
    end: number;
  };
  onSetRuneStep?: (
    runeStep: TRune,
    newData?: {
      balance: number;
      skins: TSkins | undefined;
      tasks: TTask | undefined;
    }
  ) => void;
  setCounter: React.Dispatch<
    React.SetStateAction<{
      start: number;
      end: number;
    }>
  >;
}

function countValidProperties(obj?: any) {
  if (!obj) return 0;

  let count = 0;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        obj[key] === true ||
        (typeof obj[key] === "string" && obj[key].trim() !== "")
      ) {
        count++;
      }
    }
  }
  return count;
}

export const WSkins: React.FC<IProps> = ({ setCounter, onSetRuneStep }) => {
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const { user, setClaimState, isLoading } = React.useContext(UserContext);
  const claimState = user?.claim_state;
  const claimStateSkins = user?.claim_state?.skins;

  const trustWalletTasksCompleted =
    countValidProperties(user?.claim_state?.tasks?.collaboration) === 6;

  const [skinsToBuy, setSkinsToBuy] = React.useState<ESKins | null>(null);

  const handleChangeSkinToBuy = (skin: TWSkinsItems) => {
    setSkinsToBuy(skin.type);
  };

  const handleModalClose = () => {
    setSkinsToBuy(null);
  };

  const handleSelectSkin = (skin: TWSkinsItems) => {
    if (skin.selected) {
      return;
    }

    let newClaimState = {
      skins: claimState?.skins ?? {},
    };

    Object.keys(newClaimState.skins)?.forEach((key) => {
      if (newClaimState && newClaimState.skins && newClaimState?.skins?.[key]) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [key]: {
            ...newClaimState.skins[key],
            selected: false,
          },
        };
      }
    });

    if ((newClaimState.skins, skin.type)) {
      newClaimState.skins = {
        ...newClaimState.skins,
        [skin.type]: {
          purchased: true,
          selected: true,
          type: skin.type,
        },
      };
    }
    const newData = {
      skins: newClaimState.skins,
    };

    updateUserExternalApi({ ...newData })
      .then(() => {
        // @ts-ignore
        setClaimState?.((prevState) => ({
          ...(prevState || {}),
          ...newData,
        }));
      })
      .catch((e: any) => {
        console.warn(e);
      });
  };

  const W_SKINS =
    [
      {
        title: "TON",
        price: 0,
        selected: claimStateSkins?.[ESKins.TON]?.selected || false,
        purchased: true,
        icon: tonIcon,
        type: ESKins.TON,
      },
      {
        title: "Ethereum",
        price: 0,
        selected: claimStateSkins?.[ESKins.ETHEREUM]?.selected || false,
        purchased: true,
        icon: ethIcon,
        type: ESKins.ETHEREUM,
      },
      {
        title: "Solana",
        price: 0,
        selected: claimStateSkins?.[ESKins.SOLANA]?.selected || false,
        purchased: true,
        icon: solIcon,
        type: ESKins.SOLANA,
      },
      trustWalletTasksCompleted
        ? {
            title: "Trust Wallet",
            price: 0,
            selected: claimStateSkins?.[ESKins.TRUST]?.selected || false,
            purchased: true,
            icon: trustIcon,
            type: ESKins.TRUST,
          }
        : undefined,
      {
        title: "W-Coin Retro",
        price: 5_000,
        selected: claimStateSkins?.[ESKins.RETRO]?.selected || false,
        purchased: claimStateSkins?.[ESKins.RETRO]?.purchased || false,
        icon: skinsRetroCoinIcon,
        type: ESKins.RETRO,
      },
      {
        title: "W-Coin Default",
        price: 0,
        selected:
          Object.values(claimStateSkins || {}).find((skin) => skin.selected)
            ?.type === ESKins.DEFAULT || false,
        purchased: claimStateSkins?.[ESKins.DEFAULT]?.purchased || true,
        icon: taskSkinsDefaultIcon,
        type: ESKins.DEFAULT,
      },
      {
        title: "Shuriken",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.SHURIKEN]?.selected || false,
        purchased: claimStateSkins?.[ESKins.SHURIKEN]?.purchased || false,
        icon: taskPlayShurikenIcon,
        type: ESKins.SHURIKEN,
      },
      {
        title: "Catizen",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.CATIZEN]?.selected || false,
        purchased: claimStateSkins?.[ESKins.CATIZEN]?.purchased || false,
        icon: taskPlayCatizenIcon,
        type: ESKins.CATIZEN,
      },
      {
        title: "Dot",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.DOT]?.selected || false,
        purchased: claimStateSkins?.[ESKins.DOT]?.purchased || false,
        icon: taskDotIcon,
        type: ESKins.DOT,
      },
      {
        title: "Cats",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.CATS]?.selected || false,
        purchased: claimStateSkins?.[ESKins.CATS]?.purchased || false,
        icon: catsIcon,
        type: ESKins.CATS,
      },
      {
        title: "Rune",
        price: 5_000,
        selected: claimStateSkins?.[ESKins.RUNE]?.selected || false,
        purchased: claimStateSkins?.[ESKins.RUNE]?.purchased || false,
        icon: runeIcon,
        type: ESKins.RUNE,
      },
      {
        title: "#FREEDUROV",
        price: 0,
        selected: claimStateSkins?.[ESKins.FREE_DUROV]?.selected || false,
        purchased: claimStateSkins?.[ESKins.FREE_DUROV]?.purchased || false,
        icon: durovIcon,
        type: ESKins.FREE_DUROV,
      },
    ].filter((i) => i) || [];

  const currentSkins =
    W_SKINS.find((item) => item!.type === skinsToBuy) || null;

  const handBuySkin = () => {
    if (!currentSkins) {
      return;
    }

    if (claimState?.id) {
      let newClaimState = { ...claimState };
      newClaimState.skins = newClaimState.skins ?? {};

      if (currentSkins.type === ESKins.RETRO) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.RETRO]: {
            purchased: true,
            selected: false,
            type: ESKins.RETRO,
          },
        };
      } else if (currentSkins.type === ESKins.SHURIKEN) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.SHURIKEN]: {
            purchased: true,
            selected: false,
            type: ESKins.SHURIKEN,
          },
        };
      } else if (currentSkins.type === ESKins.CATIZEN) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.CATIZEN]: {
            purchased: true,
            selected: false,
            type: ESKins.CATIZEN,
          },
        };
      } else if (currentSkins.type === ESKins.DOT) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.DOT]: {
            purchased: true,
            selected: false,
            type: ESKins.DOT,
          },
        };
      } else if (currentSkins.type === ESKins.SOLANA) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.SOLANA]: {
            purchased: true,
            selected: false,
            type: ESKins.SOLANA,
          },
        };
      } else if (currentSkins.type === ESKins.ETHEREUM) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.ETHEREUM]: {
            purchased: true,
            selected: false,
            type: ESKins.ETHEREUM,
          },
        };
      } else if (currentSkins.type === ESKins.TON) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.TON]: {
            purchased: true,
            selected: false,
            type: ESKins.TON,
          },
        };
      } else if (currentSkins.type === ESKins.CATS) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.CATS]: {
            purchased: true,
            selected: false,
            type: ESKins.CATS,
          },
        };
      } else if (currentSkins.type === ESKins.TRUST) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.TRUST]: {
            purchased: true,
            selected: false,
            type: ESKins.TRUST,
          },
        };
      } else if (currentSkins.type === ESKins.RUNE) {
        // Specific logic for RUNE skin
        const updatedSkins: TSkins = {};

        Object.entries({ ...newClaimState.skins }).forEach(([key, skin]) => {
          updatedSkins[key] = { ...skin, selected: false };
        });

        newClaimState.tasks = {
          ...newClaimState.tasks,
          // @ts-ignore
          rune: { step: ERune.START, completed: false },
        };

        newClaimState.skins = {
          ...updatedSkins,
          [ESKins.RUNE]: { purchased: true, selected: true, type: ESKins.RUNE },
        };
      } else if (currentSkins.type === ESKins.FREE_DUROV) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.FREE_DUROV]: {
            purchased: true,
            selected: false,
            type: ESKins.FREE_DUROV,
          },
        };
      }

      if (typeof newClaimState.balance === "number") {
        const newData = {
          balance: newClaimState.balance - currentSkins.price,
          skins: newClaimState.skins,
          tasks: newClaimState.tasks,
        };

        updateUserExternalApi({ ...newData })
          .then(() => {
            // @ts-ignore
            setClaimState?.((prevState) => ({
              ...(prevState || {}),
              ...newData,
            }));
            setCounter({ start: claimState!.balance, end: newData.balance });

            if (currentSkins.type === ESKins.RUNE) {
              onSetRuneStep?.(
                {
                  step: ERune.START,
                  completed: false,
                },
                newData
              );
            }
          })
          .catch((e: any) => {
            console.warn(e);
          });
      }
    }
  };

  const status = (item: TWSkinsItems) => {
    if (item.type === ESKins.FREE_DUROV && !item.purchased) {
      return "Price: Freedom";
    } else if (item.selected) {
      return "Selected";
    } else if (item.price === 0 || item.purchased) {
      return "You own it";
    }

    return formatValues(item.price);
  };

  const isRunePurchased =
    currentSkins?.purchased && currentSkins?.type === ESKins.RUNE;

  return (
    <>
      {!!skinsToBuy && currentSkins && !isRunePurchased && (
        <div
          className={`overlay app-modal-root ${!!currentSkins ? "blur" : ""}`}
        ></div>
      )}
      {updateUserState?.isLoading && (
        <div className="app-spinner" style={{ top: 0, left: 0 }}>
          <Oval
            visible={updateUserState?.isLoading}
            height="100"
            width="100"
            color="#9747FF"
            secondaryColor="rgb(225, 219, 239)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      <WSkinsModal
        open={!!currentSkins && !isRunePurchased}
        onClose={handleModalClose}
        onUpdate={handBuySkin}
        skinsToBuy={currentSkins}
        balance={claimState?.balance || 0}
        isPurchased={currentSkins?.purchased}
        isLoading={isLoading}
      />
      {W_SKINS.map((item, index) => {
        return (
          <div
            key={index}
            className="main-boosters-root"
            onClick={
              item!.purchased
                ? // @ts-ignore
                  () => handleSelectSkin(item)
                : // @ts-ignore
                  () => handleChangeSkinToBuy(item)
            }
          >
            <div className="main-boosters-left">
              <div className="main-boosters-avatar">
                <ZoomInAnimation>
                  <img width="75px" height="75px" src={item!.icon} alt="" />
                </ZoomInAnimation>
              </div>
              <div className="main-boosters-description-wrapper ">
                <p className="green-color">{item!.title}</p>
                <div className="main-boosters-description">
                  {!(item!.purchased || item!.selected) && (
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                  )}
                  <p className="main-boosters-price common-color">
                    {/** @ts-ignore */}
                    {status(item)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
