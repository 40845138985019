import React, { useMemo, useCallback, memo } from "react";

import { TAppUser, TClaimState } from "../../../contexts/types";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { useCampaignApi } from "./hooks";

import "./index.scss";
import { ICampaign } from "./models";
import { VerifyModal } from "./verify-modal";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";

const campaignLogo = require("./img/bitget-logo.png") as string;
const checkedImg = require("./img/checked.svg").default as string;
const completedTasksImg = require("./img/completed.png") as string;

interface IProps {
  user: TAppUser;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
}

export const BitgetWalletCampaign: React.FC<IProps> = memo(({ user, setClaimState }) => {
  const {
    activeTask,
    isLoading,
    isAllTasksDone,
    tasksToDisplay,
    onModalOpen,
    completedTasks,
    allTasks,
    setActiveTask,
    onClaimTask,
    onCheckUidModalOpen,
    payPassMultiplier,
  } = useCampaignApi(user, setClaimState);

  const onTaskClick = useCallback(
    (task: ICampaign) => {
      if (task.is_done) return;
      onModalOpen(task);
    },
    [onCheckUidModalOpen, onModalOpen]
  );

  const renderedTasks = useMemo(
    () =>
      tasksToDisplay.map((task) => (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task.type}
          style={{
            position: "relative",
          }}
        >
          <img
            className="collab-item-icon"
            src={task?.is_done ? checkedImg : task.icon}
            alt=""
            width="70px"
            height="70px"
          />
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                <div>{task.title}</div>
                {!task?.is_done && (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="black-color-campaign">+{formatValues(task.reward)}</p><PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )),
    [tasksToDisplay, onTaskClick]
  );

  return (
    <div
      className="task-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <VerifyModal
        currentTask={activeTask}
        onClose={() => setActiveTask(null)}
        onClaim={
          activeTask ? () => onClaimTask({ task: activeTask }) : undefined
        }
        isLoading={isLoading}
        showVerifyModal={!!activeTask?.type}
        payPassMultiplier={payPassMultiplier}
        
      />
      <div
        className="task-list-main-wrapper bitget-bg"
        style={{ height: isAllTasksDone ? "100%" : "80%" }}
      >
        <div className="task-list-wrapper">
          {isAllTasksDone ? (
            <div className="collab-done">
              <img width="115px" src={completedTasksImg} alt="" />
              <div className="collab-done-desc-bitget">
                <p style={{color: '#000!important'}}>Congratulations!</p>
                <p style={{color: '#000!important'}}>You have successfully</p>
                <p style={{color: '#000!important'}}>completed all tasks!</p>
              </div>
            </div>
          ) : (
            renderedTasks
          )}
        </div>
        <div className="collab-footer" style={{color: '#000'}}>
          {!isAllTasksDone && (
            <div>
              Tasks completed:{" "}
              <b className="collab-footer-counter ab" style={{color: '#000'}}>
                {completedTasks}/{allTasks}
              </b>
            </div>
          )}
        </div>
      </div>
      <img
      width="287px"
        style={{ display: "flex", margin: "0 auto" }}
        src={campaignLogo}
        alt=""
      />
    </div>
  );
});
