import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";
import { BG_FROM_LS_STATE, SUPPORT_LINK } from "../../consts";
import { useUpdateUserExternalApi } from "../../hooks";

export const usePageSelectedBlockchainApi = () => {
  const [impactOccurred] = useHapticFeedback();
  const webApp = useWebApp();

  const { user, setClaimState, isLoading, isFetching } =
    React.useContext(UserContext);

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const [isAnimationOn, setIAnimationsOn] = React.useState(true);

  React.useEffect(() => {
    const showAnimation = user?.claim_state?.boosts?.showAnimation;
    if (typeof showAnimation === "undefined") {
      setIAnimationsOn(true);
    } else {
      setIAnimationsOn(showAnimation);
    }
  }, [user?.claim_state?.boosts]);

  const handleOpenSupport = () => {
    webApp.openTelegramLink(SUPPORT_LINK);
  };

  const animationToggler = () => {
    const claimState = user?.claim_state;
    const newValue = !isAnimationOn;

    const boosts = claimState?.boosts
      ? { ...claimState.boosts, showAnimation: newValue }
      : { showAnimation: newValue };

      const newData = {
        boosts,
      };

      updateUserExternalApi({ ...newData })
        .then(() => {
          // @ts-ignore
          setClaimState?.((prevState) => ({
            ...(prevState || {}),
            ...newData,
          }));
        })
        .catch((e: any) => {
          console.warn(e);
        })
        .finally(() => {
          impactOccurred("heavy");
          localStorage.setItem(BG_FROM_LS_STATE, JSON.stringify(newValue));
          setIAnimationsOn(newValue);
        });
  };

  return {
    isLoading,
    isFetching: isFetching || updateUserState.isLoading,
    userName: user.claim_state?.telegram_username,
    onOpenSupport: handleOpenSupport,
    isAnimationSwitcherOn: isAnimationOn,
    onAnimationToggler: animationToggler,
  };
};
