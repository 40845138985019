import React from "react";

import { TChest, EModalType } from "../models";

import "./index.scss";

const onboardingIcon = require("../img/onboarding-icon.png") as string;

interface IProps {
  onShowOnboarding: (value?: boolean) => void;
}

export const Header: React.FC<IProps> = ({ onShowOnboarding }) => {
  return (
    <div
      className="page-claim-header"
      style={{ position: "relative", padding: 0 }}
    >
      <div className="page-claim-header-root">
        <p className="chest-header-label ab" style={{ fontSize: "22px" }}>
          W-TREASURES
        </p>
        <div className="icons page-claim-icons">
          <button onClick={() => onShowOnboarding()}>
            <img width="22px" height="22px" src={onboardingIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
