import React from "react";

import "./index.scss";

const basketIcon = require("./img/basket-icon.png") as string;
const arrowIcon = require("./img/arrow-icon.png") as string;
const keyIcon = require("./img/key-icon.png") as string;
const chestIcon = require("./img/chest-icon.png") as string;

export const Slide1: React.FC = () => {
  return (
    <div className="page-chest-onboarding-slide1-wrapper">
      <div className="page-chest-onboarding-slide1-header">
        <p className="page-chest-onboarding-slide1-header-hight">
          How does W-Chest
        </p>
        <p className="page-chest-onboarding-slide1-header-low ab">work?</p>
      </div>
      <div className="page-chest-onboarding-slide1-body">
        <div className="page-chest-onboarding-slide1-body-item">
          <div className="page-chest-onboarding-slide1-body-item-img">
            <img src={basketIcon} alt="" width="65px" height="67px" />
          </div>
          <p className="page-chest-onboarding-slide1-body-item-text">
            1. Go to Key Shop{" "}
          </p>
        </div>
        <div className="page-chest-onboarding-slide1-body-item">
          <div className="page-chest-onboarding-slide1-body-item-img-arrow">
            <img src={arrowIcon} alt="" height="41px" />
          </div>
        </div>
        <div className="page-chest-onboarding-slide1-body-item">
          <div className="page-chest-onboarding-slide1-body-item-img">
            <img src={keyIcon} alt="" width="69px" height="79px" />
          </div>
          <p className="page-chest-onboarding-slide1-body-item-text">
            2. Grab some W&#8209;Keys
          </p>
        </div>
        <div className="page-chest-onboarding-slide1-body-item">
          <div className="page-chest-onboarding-slide1-body-item-img-arrow">
            <img src={arrowIcon} alt="" height="41px" />
          </div>
        </div>
        <div className="page-chest-onboarding-slide1-body-item">
          <div className="page-chest-onboarding-slide1-body-item-img">
            <img src={chestIcon} alt="" width="108.31px" height="117.58px" />
          </div>
          <p className="page-chest-onboarding-slide1-body-item-text">
            3. Open W&#8209;Chest and win big
          </p>
        </div>
      </div>
    </div>
  );
};
