import React from "react";

import { Stack } from "@mui/material";

import { EModalType } from "../../models";
import { Spinner } from "../../../spinner";

import { Keys } from "./keys";
import { CheckTransaction } from "./check-transaction";
import { useKeyShopContentApi } from "./hooks";

interface IProps {
  onModalTypeClick: (value: EModalType | null) => void;
}

export const KeyShopContent: React.FC<IProps> = ({ onModalTypeClick }) => {
  const {
    showKeysContent,
    currentKey,
    address,
    tonConnectUI,
    onDisconnect,
    onCurrentKey,
    onOpenCheckTransaction,
    onBuyKeys,
    onCloseCheckTransaction,
    openedTonOrders,
    openedStarsOrders,
    onCloseOrder,
    onCheckTransactionKeys,
    transactionId,
    isLoading,
    inputCode,
    onInputChange,
    errorMessage,
    onBuyKeyBuyStars,
    currentPurchaseType,
  } = useKeyShopContentApi(onModalTypeClick);

  return (
    <Stack
      className="app-modal-content task-modal-content"
      style={{ justifyContent: "space-between", gap: "16px" }}
      overflow="auto"
    >
      <Spinner
        isLoading={isLoading}
        label="Verifying transaction…"
        marginTop="-26px"
      />
      {showKeysContent ? (
        <Keys
          currentKey={currentKey}
          address={address}
          tonConnectUI={tonConnectUI}
          onDisconnect={onDisconnect}
          onCurrentKey={onCurrentKey}
          onOpenCheckTransaction={onOpenCheckTransaction}
          onBuyKeys={onBuyKeys}
          openedTonOrders={openedTonOrders}
          openedStarsOrders={openedStarsOrders}
          onBuyKeyBuyStars={onBuyKeyBuyStars}
          currentPurchaseType={currentPurchaseType}
        />
      ) : (
        <CheckTransaction
          openedTonOrders={openedTonOrders}
          openedStarsOrders={openedStarsOrders}
          onCloseModal={onCloseCheckTransaction}
          onCloseOrder={onCloseOrder}
          onCheckTransactionKeys={onCheckTransactionKeys}
          transactionId={transactionId}
          inputCode={inputCode}
          onInputChange={onInputChange}
          errorMessage={errorMessage}
          currentPurchaseType={currentPurchaseType}
          currentKey={currentKey}
        />
      )}
    </Stack>
  );
};
