import React from "react";

import { Link } from "react-router-dom";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { BoostersModal } from "../app-modal/boosters-modal";
import { ROUTES } from "../routers";
import { Counter } from "../counter";
import { ERune } from "../hooks/rune/models";
import { TemplatePages } from "../template-pages";

import "./index.scss";
import { usePageBoostersApi } from "./hooks";
import { Boosters } from "./boosters";

// images
const bg = require("../page-mates/img/mates-bg.jpg") as string;
const nIcon = require("../../components/hooks/rune/img/n-icon.png") as string;

export const PageBoosters: React.FC = () => {
  const {
    isLoading,
    isFetching,
    claimState,
    currentType,
    onModalClose,
    onModalOpen,
    onUpdateBoosters,
    counter,
    setCounter,
    availableEnergyBoosts,
    availableDiceBoosts,
    availableMultiplierBoosts,
    availableWorkerBoosts,
    onSetRuneStep,
    payPassMultiplier,
    user,
  } = usePageBoostersApi();

  const setLevel = (level?: number) => {
    if (level || level === 0) {
      return level && level === 0 ? 1 : level + 1;
    }
    return 0;
  };
  const multiplierLevel = setLevel(claimState?.click_multiplier_level);
  const chargerLevel = setLevel(claimState?.energy_refill_multiplier_level);
  const maximizerLevel = setLevel(claimState?.max_energy_level);
  // @ts-ignore
  const runeStep = user?.claim_state?.tasks?.rune?.step === ERune.I;

  return (
    <TemplatePages isLoading={isLoading} isFetching={isFetching} bg={bg}>
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <div className={`page page-1 page-tasks main-wrapper`}>
        {runeStep && (
          <img
            src={nIcon}
            onClick={() =>
              onSetRuneStep?.({
                step: ERune.N,
                completed: false,
              })
            }
            alt=""
            className="c-rune-icon"
            style={{
              bottom: "200px",
            }}
          />
        )}

        {currentType && (
          <div
            className={`overlay app-modal-root ${currentType ? "blur" : ""}`}
          ></div>
        )}
        <BoostersModal
          open={currentType}
          onClose={onModalClose}
          onUpdateBoosters={onUpdateBoosters}
          multiplierLevel={multiplierLevel}
          chargerLevel={chargerLevel}
          maximizerLevel={maximizerLevel}
          claimState={claimState}
          payPassMultiplier={payPassMultiplier}
        />
        <div className="content mates-content task-booster">
          <div className="boosters-heading">
            <Counter counter={counter} />
            <Link
              target="_blank"
              className="font-size-16 mates-tg-link purple-color "
              to="https://telegra.ph/How-boosters-work-04-15"
            >
              How W-boosters work?
            </Link>
          </div>
          {claimState && (
            <Boosters
              onOpen={onModalOpen}
              multiplierLevel={multiplierLevel}
              chargerLevel={chargerLevel}
              maximizerLevel={maximizerLevel}
              isMultiplierUsed={availableMultiplierBoosts}
              isWorkerBoostsUsed={availableWorkerBoosts}
              availableEnergyBoosts={availableEnergyBoosts}
              availableDiceBoosts={availableDiceBoosts}
              counter={counter}
              setCounter={setCounter}
              onSetRuneStep={onSetRuneStep}
              // @ts-ignore
              runeType={user?.claim_state?.tasks?.rune?.type}
              payPassMultiplier={payPassMultiplier}
            />
          )}
        </div>
      </div>
    </TemplatePages>
  );
};
