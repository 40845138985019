import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import WebApp from "@twa-dev/sdk";

import { EModalType, TKey } from "../../models";
import { useConnectWalletApi, usePageChestApi } from "../../hooks";
import { useUpdateKeyOrderMutation, useUpdateKeyOpenOrderMutation, useUpdateKeyStarsOpenOrderMutation } from "../../../feature/users";
import { KEYS_DATA } from "../../chest-list/consts";
import { useInputCheckCode } from "./check-transaction/utils";
import { showToast } from "../../../../utils";

import { ECurrentPurchaseType } from "./models";

export const useKeyShopContentApi = (onModalTypeClick: (value: EModalType | null) => void
) => {
  const [impactOccurred] = useHapticFeedback();

  const { chests } = usePageChestApi();

  const { inputCode, onInputChange, setErrorMessage, errorMessage } = useInputCheckCode();

  const transactionId = chests?.data.pending_orders.ton[0]?.transaction_id;

  const { address, tonConnectUI, onDisconnect, handleSentTransaction } =
    useConnectWalletApi();

  const [openKeyOrder,  openKeyOrderState] = useUpdateKeyOpenOrderMutation();
  const [openKeyStarsOrder,  openKeyOrderStarsState] = useUpdateKeyStarsOpenOrderMutation();

  const [updateKeyOrder, openCloseState] = useUpdateKeyOrderMutation();
  const isLoading = openKeyOrderState.isLoading || openCloseState.isLoading || openKeyOrderStarsState.isLoading;

  const [showKeysContent, setShowKeysContent] = React.useState<boolean>(true);
  const [currentKey, setCurrentKey] = React.useState<TKey | null>(null);
  const [currentPurchaseType, setCurrentPurchaseType] = React.useState<ECurrentPurchaseType | null>(null)

  const openedTonOrders = chests?.data.pending_orders?.ton
  const openedStarsOrders = chests?.data.pending_orders?.telegram_stars

  const handleResetCurrentKey = () => {
    impactOccurred('heavy');
    setCurrentKey(null);
  }

  const handleCurrentKey = (value: TKey) => {
    impactOccurred('heavy');
    if (openedTonOrders?.length || openedStarsOrders?.length) {
      return;
    }

    setCurrentKey(value);
  };

  const handleOpenCheckTransaction = () => {
    impactOccurred('heavy');
    onModalTypeClick(EModalType.CHECK_STANSACTION);
    setShowKeysContent(false);
  };

  const handleCloseCheckTransaction = () => {
    impactOccurred('heavy');
    onModalTypeClick(EModalType.KEY_SHOP);
    setShowKeysContent(true);
  };

  const onSuccessKeys = (orderId: string, transaction_id: string) => {
    if (currentKey && orderId && transaction_id) {
      updateKeyOrder({
        order_id: orderId,
        status:'success',
        transaction_id: transaction_id,
        type: 'ton',
      }).unwrap().then((res) => {
        handleCloseCheckTransaction();
        handleResetCurrentKey();
        showToast('Keys purchased');
        impactOccurred("heavy");
        onModalTypeClick(null);
        setCurrentPurchaseType(null);

        setTimeout(() => {
          onDisconnect();
        }, 5000)
      })
    }
  };

  const onCheckTransactionKeys = (transaction_id: string) => {
    impactOccurred('heavy');
    const id = transactionId || transaction_id; 
    const orderId =  openedTonOrders?.[0]?.order_id;

    if (currentKey && id && orderId) {
      return updateKeyOrder({
        status: 'success',
        order_id: orderId,
        transaction_id: id,
        type: 'ton',
      }).unwrap().then(() => {
        handleResetCurrentKey();
        handleCloseCheckTransaction();
        showToast('Keys purchased');
        impactOccurred("heavy");
        onModalTypeClick(null);

        setTimeout(() => {
          onDisconnect();
        }, 5000)
      }).catch(() => {
        setErrorMessage(true)
      })
    }
  };

  const onBuyKeys = () => {
    impactOccurred('heavy');
    if (currentKey) {   
      openKeyOrder({product_id: currentKey.type, amount: currentKey.amount}).unwrap().then((res) => {
          const orderId = res?.data?.order?.id;
          setCurrentPurchaseType(ECurrentPurchaseType.TON);

          if (orderId) {
            handleSentTransaction(currentKey, orderId, onSuccessKeys)
          }
        }).catch((e) => console.log(e));
    }
  };

  const handleCloseOrder = () => {
    impactOccurred('heavy');
    const orderId = (openedTonOrders?.[0]?.order_id||
      openedStarsOrders?.[0]?.order_id)

      if (orderId) {
        if (currentPurchaseType === ECurrentPurchaseType.TON) {
          updateKeyOrder({
            order_id: orderId,
            status:'cancel',
            type: 'ton',
          }).then(() => {
            impactOccurred("heavy");
            handleCloseCheckTransaction();
            setCurrentPurchaseType(null);
          })
        } else {
          updateKeyOrder({
            order_id: orderId,
            status:'cancel',
            type: 'tg-stars',
          }).then(() => {
            impactOccurred("heavy");
            handleCloseCheckTransaction();
            setCurrentPurchaseType(null);
          })
        }
    }
  }

  const handleBuyKeyBuyStars = () => {
    impactOccurred('heavy');
    setCurrentPurchaseType(ECurrentPurchaseType.STARS);

    if (currentKey?.type) {
      openKeyStarsOrder({
        product_id: currentKey.type,
      }).unwrap().then((response) => {
        const starsOrderId = response?.data?.order?.id
        const starsLink = response?.data?.order?.meta?.invoice?.result as string;

        if (starsOrderId && starsLink) {
          const starsLink = response?.data?.order?.meta?.invoice?.result as string;

            try {
              WebApp.openInvoice(starsLink, (status) => {
                if (status === "paid") {
                  updateKeyOrder({
                    order_id: starsOrderId,
                    status:'success',
                    transaction_id: 'stars_transactions',
                    type: 'tg-stars',
                  }).unwrap().then((res) => {
                    handleCloseCheckTransaction();
                    handleResetCurrentKey();
                    showToast('Stars purchased');
                    impactOccurred("heavy");
                    setCurrentPurchaseType(null);
            
                    setTimeout(() => {
                      onDisconnect();
                    }, 5000)
                  })
                }
              });
            } catch (error) {
              console.log(error);
            }
        }
      }).catch((e) => {
          console.log(e, 'error opened stars transaction')
      })
    }
  }

  React.useEffect(() => {
    const orders = (openedTonOrders?.length ?  openedTonOrders : openedStarsOrders);

    if (orders && orders?.length > 0) {

      const findCurrentKey = KEYS_DATA.find(
        (key) => key.type === orders[0].product_id
      ) as TKey;
      if (findCurrentKey) {
        setCurrentKey(findCurrentKey);
        const tonType = openedTonOrders?.length ? ECurrentPurchaseType.TON : null;
        const starsType = openedStarsOrders?.length ? ECurrentPurchaseType.STARS : null;
        const type = tonType || starsType;

        if (type) {
          setCurrentPurchaseType(type)
        }
      }
    }
  }, [chests?.data, openedTonOrders, openedStarsOrders]);

    return {
        showKeysContent,
        currentKey,
        address,
        tonConnectUI,
        onDisconnect,
        onCurrentKey: handleCurrentKey,
        onOpenCheckTransaction: handleOpenCheckTransaction,
        onCloseCheckTransaction: handleCloseCheckTransaction,
        onBuyKeys,
        openedTonOrders,
        openedStarsOrders,
        onCloseOrder: handleCloseOrder,
        onCheckTransactionKeys,
        transactionId,
        isLoading,
        inputCode,
        onInputChange,
        errorMessage,
        onBuyKeyBuyStars: handleBuyKeyBuyStars,
        currentPurchaseType,
    }
}