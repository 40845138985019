import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useNavigate } from "react-router-dom";

import { Links } from "../links";
import { ROUTES } from "../../routers";
const waiIcon = require("../img/wai.png") as string;
const rocketIcon = require("../img/rocket.png") as string;
const tasksIcon = require("../img/tasks.png") as string;
const matesIcon = require("../img/mates.png") as string;

interface IProps {
  activeTaskCount: number;
}

export const Footer: React.FC<IProps> = ({ activeTaskCount }) => {
  const [impactOccurred] = useHapticFeedback();
  const navigate = useNavigate();

  const onGoToDashboard = () => {
    navigate(ROUTES.DASHBOARD.PATH, { state: { stayHerePage: true } });
  };

  return (
    <div className="bottom-nav">
      <Links
        path={`#${ROUTES.MATES.PATH}`}
        icon={matesIcon}
        label="Mates"
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.TASKS.PATH}`}
        icon={tasksIcon}
        label="Tasks"
        activeTaskCount={activeTaskCount}
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.WAI.PATH}`}
        icon={waiIcon}
        label="W-AI"
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.BOOSTERS.PATH}`}
        icon={rocketIcon}
        label="Boost"
        width={30}
        height={30}
      />
    </div>
  );
};
