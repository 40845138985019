import React from 'react';

interface ImageProps {
    src: string;
    alt: string;
    width: string | number;
    height: string | number;
}

export const Benefits: React.FC<ImageProps> = ({ src, alt, width, height }) => {
    return <img src={src} alt={alt} width={width} height={height} />;
};
