import React from "react";

import logo from "./img/wlogo.svg";

import "./index.scss";

interface IProps {
  isLoading: boolean;
}

export const Loader: React.FC<IProps> = ({ isLoading }) => {
  return isLoading ? (
    <div className="app-spinner">
      <img width="150px" src={logo} alt="Loading..." className="pulse-logo" />
    </div>
  ) : null;
};
