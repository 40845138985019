// @ts-nocheck
import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import {
  useGetTasksQuery,
  useLazyGetValidateUserInGroupQuery,
} from "../feature/users";
import { CHARGER_UPGRADES } from "../page-boosters/boosters/consts";
import { BALANCE_COIN_SIZE_X_LARGE } from "../../consts";
import { TAppUser, TClaimState } from "../contexts/types";
import { SmallCoin } from "../page-claim/icons/small-coin";
import { useUpdateUserExternalApi } from "../../hooks";
import { TInviteThreeMatesTask } from "../../models";

import { ETab, ETask, ITask, TAdsGramParams } from "./type";
import {
  ADSGRAM_FIELD,
  MAX_BALANCE_LEVEL,
  MAX_CLICK_LEVEL,
  MAX_TASK_TRY_STAKING_LEVEL,
  canGetReward,
  getNextLevel,
  getTaskVisitEveryDayReward,
} from "./consts";
import {
  checkNickname,
  getCurrentTimestamp,
  getDifferentDays,
  hasDayPassedSince,
  shouldShow,
  updateDateIfNeeded,
} from "./utils";

const icon1 = require("./img/telegram.svg").default as string;
const icon2 = require("./img/twitter.svg").default as string;
const taskPremiumIcon = require("./img/task_premium.svg").default as string;
const icon6 = require("./img/connectwallet.svg").default as string;
const everyDayIcon = require("./img/daily.png") as string;
const bg = require("../page-mates/img/mates-bg.jpg") as string;
const okxImageBg = require("./img/campaign-bg.png") as string;
const azureImageBg = require("./img/azur-games-icon.jpg") as string;
const bitgetImageBg = require("./img/bitgetImg.png") as string;
// const wcoin7daysIcon = require("./img/wcoin7days.png") as string;
// const legendIcon = require("./img/legend-logo.png");

export function useAdsGram({
  blockId,
  onReward,
}: TAdsGramParams): () => Promise<void> {
  const AdControllerRef = React.useRef<{ current: any } | undefined>(undefined);

  React.useEffect(() => {
    // @ts-ignore
    if (window?.Adsgram) {
      // @ts-ignore
      AdControllerRef.current = window.Adsgram?.init({
        blockId,
        debug: false,
      });
    }
  }, [blockId]);

  return React.useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        // @ts-ignore
        .show()
        .then(() => {
          onReward();
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      console.log("AdsGram error");
    }
  }, [onReward]);
}

export const usePageTasksApi = ({
  user,
  isLoading,
  isSuccess,
  setClaimState,
  canGetUser,
}: {
  user: TAppUser;
  isLoading: boolean;
  isSuccess: boolean;
  setClaimState?:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined;
  canGetUser: symbol | undefined;
}) => {
  const webApp = useWebApp();

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [impactOccurred] = useHapticFeedback();

  const [currentIndexTask, setCurrentIndexTask] = React.useState<ETask | null>(
    null
  );

  const claimState = user?.claim_state;

  function saveDailyTimestamp() {
    const daysDifference = getDifferentDays(claimState?.task_visit_every_day);

    const newData = {
      task_visit_every_day: null,
    } as TClaimState;

    if (daysDifference > 2) {
      updateUserExternalApi({
        ...newData,
      })
        .then(() => {
          setClaimState?.((prevState) => ({ ...prevState, ...newData }));
          return;
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  }

  React.useEffect(() => {
    if (isSuccess) {
      saveDailyTimestamp();
    }
  }, [claimState?.task_visit_every_day, isSuccess]);

  const [fetchValidateUserInGroup, state] =
    useLazyGetValidateUserInGroupQuery();

  const handleTelegramGroupVerify = (resource: string) => {
    return fetchValidateUserInGroup({ resource });
  };

  const canGetVisitReward = React.useMemo(() => {
    if (claimState?.task_visit_every_day === null) {
      return true;
    }

    const daysDifference = getDifferentDays(claimState?.task_visit_every_day);

    return daysDifference >= 1 && daysDifference <= 2;
  }, [claimState?.task_visit_every_day]);

  const readyToClaim: Record<ETask.INVITE_MATE_CLAIMED, boolean> | {} =
    React.useMemo(() => {
      try {
        const stakingAmount = claimState?.stakes?.reduce(
          (acc, curr) => acc + curr.amount,
          0
        );

        return {
          [ETask.INVITE_MATE_CLAIMED]:
            claimState?.newMates && claimState.newMates?.count_mates > 0,
          [ETask.TASK_UPGRADE_CHARGER_TO_MAX_CLAIMED]:
            claimState?.energy_refill_multiplier_level
              ? Number(claimState?.energy_refill_multiplier_level) ===
                Object.values(CHARGER_UPGRADES).length
              : false,
          [ETask.TASK_TRY_STAKING_CLAIMED]: canGetReward(
            ETask.TASK_TRY_STAKING_CLAIMED,
            claimState?.task_stake_level,
            stakingAmount
          ),
          [ETask.TASK_CLICK_LEVEL]: canGetReward(
            ETask.TASK_CLICK_LEVEL,
            claimState?.task_click_level,
            claimState?.clicks
          ),
          [ETask.TASK_VISIT_EVERY_DAY]: canGetVisitReward,
          [ETask.TASK_BALANCE_LEVEL]: canGetReward(
            ETask.TASK_BALANCE_LEVEL,
            claimState?.task_balance_level,
            claimState?.balance
          ),
          [ETask.TASK_IS_PREMIUM]:
            // @ts-ignore
            window?.Telegram?.WebApp?.initDataUnsafe?.user?.is_premium || false,
          [ETask.INVITE_THREE_MATE]: (
            claimState?.tasks?.inviteThreeMatesData as TInviteThreeMatesTask
          )?.isEnoughMates,
          // @ts-ignore
          [ETask.TASK_WCOIN_IN_NICKNAME_VISIT_EVERY_DAY]: checkNickname(
            window?.Telegram?.WebApp?.initDataUnsafe?.user?.last_name,
            claimState?.tasks?.task_wcoin_in_nickname_visit_every_day_new
              ?.timestamp
          ),
        };
      } catch (e) {
        console.warn(e);
        return {};
      }
    }, [
      canGetVisitReward,
      claimState?.balance,
      claimState?.clicks,
      claimState?.energy_refill_multiplier_level,
      claimState?.newMates,
      claimState?.stakes,
      claimState?.task_balance_level,
      claimState?.task_click_level,
      claimState?.task_stake_level,
      claimState?.tasks?.inviteThreeMatesData,
      claimState?.tasks?.task_wcoin_in_nickname_visit_every_day_new,
    ]);

  // @ts-ignore
  const ADS_COUNT = hasDayPassedSince(
    claimState?.tasks?.[ADSGRAM_FIELD]?.lastViewed
  )
    ? 0
    : claimState?.tasks?.[ADSGRAM_FIELD]?.viewedTimes;

  const WALLET_TASK: ITask = {
    icon: icon6,
    title: "Connect wallet",
    link: "",
    reward: 100000,
    is_done: !!claimState?.tasks?.connect_wallet,
    type: ETask.CONNECT_WALLET,
    btnLabel: "Follow W-Community",
    index: 500,
    category: "In-game",
    showProbability: 100,
  };

  const THREE_FRIENDS_TASK = {
    icon: "https://alohomora-tasks.fra1.cdn.digitaloceanspaces.com/images/3mates3.png",
    title: "Invite 3 Mates",
    link: null,
    reward: 500_000,
    // @ts-ignore
    is_done: claimState?.tasks?.inviteThreeMatesData?.completed || false,
    type: "inviteThreeMatesData",
    claimTask: true,
    btnLabel: "Follow W-Community",
    index: 200,
    // @ts-ignore
    isReadyToClaim:
      // @ts-ignore
      (readyToClaim as Record<"inviteThreeMatesData", boolean>)
        ?.isEnoughMates || false,
    isJSONTask: true,
    category: "Specials",
    showProbability: 100,
  } as ITask;

  const checkIsDone = (type: string) => {
    return claimState?.tasks?.[type] || false;
  };
  // @ts-ignore
  const {
    data,
    isLoading: isTasksLoading,
    isSuccess: isTaskSuccess,
  } = useGetTasksQuery(canGetUser);

  // @ts-ignore
  const currentTasks = React.useMemo(
    // @ts-ignore
    () => (data?.data?.map((i) => i.attributes?.contents) || []) as ITask[],
    // @ts-ignore
    [data?.data]
  );

  const formattedNewTasks: ITask[] = React.useMemo(() => {
    if (!currentTasks) return [];

    //@ts-ignore
    const lang = window?.Telegram?.WebApp?.initDataUnsafe?.user?.language_code;

    return currentTasks?.reduce(
      (acc: ITask[], next) => {
        if (next?.excludeLang && next.excludeLang.length > 0 && lang) {
          if (next.excludeLang.includes(lang)) {
            return acc;
          }
        }

        if (
          next?.allowedLanguages &&
          next.allowedLanguages.length > 0 &&
          lang
        ) {
          if (!next.allowedLanguages.includes(lang)) {
            return acc;
          }
        }

        if (shouldShow(next.showProbability)) {
          acc.unshift({
            ...next,
            // @ts-ignore
            is_done: checkIsDone(next.type),
          });

          return acc;
        }

        return acc;
        // @ts-ignore
      },
      [
        THREE_FRIENDS_TASK,
        {
          icon: "https://alohomora-tasks.fra1.cdn.digitaloceanspaces.com/adsgram.png",
          link: "",
          type: ADSGRAM_FIELD,
          index: 1007,
          title: `Watch daily Ad ${`${ADS_COUNT}/3`}`,
          reward: 10000,
          is_done: false,
          btnLabel: "Follow W-Community",
          category: "Web3",
          isAdverts: true,
          isJSONTask: true,
          showProbability: 100,
        },
      ]
    );
  }, [currentTasks, claimState?.tasks, ADS_COUNT]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const TASKS = [
    {
      icon: icon1,
      title: "Join our Telegram community",
      link: "",
      reward: 20_000,
      is_done: claimState?.task_join_community_claimed,
      type: ETask.TELEGRAM,
      btnLabel: "Follow W-Community",
      index: 1,
      verifyLink: "w_community",
      category: "Specials",
      showProbability: 100,
    },
    // {
    //   icon: legendIcon,
    //   title: "Join Legends Announcement",
    //   link: "https://t.me/legendsannouncements",
    //   reward: 20_000,
    //   is_done: claimState?.tasks?.['LEGEND_COMMUNITY_JOIN_TELEGRAM'] || false,
    //   type: 'LEGEND_COMMUNITY_JOIN_TELEGRAM',
    //   btnLabel: "Follow Legend Community",
    //   index: 621,
    //   verifyExternalLink: true,
    //   verifyExternalTaskName: 'JoinTelegramGroup',
    //   category: "Specials",
    //   isJSONTask: true,
    //   showProbability: 100,
    // },
    // {
    //   icon: legendIcon,
    //   title: "Play Legends Airdrop",
    //   subtitle: 'Complete 8 games at Legends and earn $TON rewards',
    //   link: "https://t.me/LegendsAirDropBot/tap?startapp=LEG-YFiwXPp8",
    //   modalLabel: 'Play 8 games at Legends $10,000 Daily Race and earn $TON rewards and $LEGENDS airdrop points',
    //   reward: 100_000,
    //   is_done: claimState?.tasks?.[EExternalVerifyTasks.LEGEND_COMMUNITY_PLAY_GAME] ?? false,
    //   type: EExternalVerifyTasks.LEGEND_COMMUNITY_PLAY_GAME,
    //   btnLabel: "Play Legends Airdrop",
    //   index: 622,
    //   verifyExternalLink: true,
    //   verifyExternalTaskName: 'Play8Games',
    //   category: "Specials",
    //   isJSONTask: true,
    //   showProbability: 100,
    // },
    // {
    //   icon: legendIcon,
    //   title: "Play Legends Airdrop",
    //   subtitle: 'Complete 8 games at Legends and earn $TON rewards',
    //   link: "https://t.me/LegendsAirDropBot/tap?startapp=LEG-YFiwXPp8",
    //   modalLabel: 'Play 8 games at Legends $10,000 Daily Race and earn $TON rewards and $LEGENDS airdrop points',
    //   reward: 100_000,
    //   is_done: claimState?.tasks?.['LEGEND_COMMUNITY_PLAY_GAME'] ?? false,
    //   type: 'LEGEND_COMMUNITY_PLAY_GAME',
    //   btnLabel: "Play Legends Airdrop",
    //   index: 622,
    //   verifyExternalLink: true,
    //   verifyExternalTaskName: 'Play8Games',
    //   category: "Specials",
    //   isJSONTask: true,
    //   showProbability: 100,
    // },
    //
    // @ts-ignore
    {
      icon: everyDayIcon,
      title: `Visit W-coin every day`,
      link: null,
      reward: getTaskVisitEveryDayReward(claimState?.task_visit_every_day),
      is_done: false,
      type: ETask.TASK_VISIT_EVERY_DAY,
      btnLabel: "Follow W-Community",
      index: 2001,
      claimTask: true,
      category: "In-game",
      showProbability: 100,
      isReadyToClaim:
        (readyToClaim as Record<ETask.TASK_VISIT_EVERY_DAY, boolean>)
          ?.task_visit_every_day || false,
    },
    {
      icon: taskPremiumIcon,
      title: "Premium reward",
      link: null,
      reward: 200_000,
      is_done: claimState?.task_is_premium_claimed,
      type: ETask.TASK_IS_PREMIUM,
      claimTask: true,
      btnLabel: "Follow W-Community",
      index: 3,
      category: "In-game",
      showProbability: 100,
      isReadyToClaim:
        (readyToClaim as Record<ETask.TASK_IS_PREMIUM, boolean>)
          ?.task_is_premium_claimed || false,
    },
    {
      icon: icon2,
      title: "Join our X community",
      link: "https://twitter.com/wcoin_io?s=21",
      reward: 10_000,
      is_done: claimState?.is_x_joined,
      type: ETask.X,
      btnLabel: "Follow W-Community",
      index: 4,
      category: "Specials",
      showProbability: 100,
    },
    WALLET_TASK,
    {
      stringIcon: "🧑🏼‍🚀",
      title: "Invite 1 Mate",
      link: null,
      reward: 50000,
      is_done: claimState?.task_invite_mate_claimed,
      type: ETask.INVITE_MATE_CLAIMED,
      btnLabel: "Follow W-Community",
      index: 6,
      category: "In-game",
      showProbability: 100,
      isReadyToClaim:
        (readyToClaim as Record<ETask.INVITE_MATE_CLAIMED, boolean>)
          ?.task_invite_mate_claimed || false,
      openPageBeforeTaskReadyType: "/mates",
      claimTask: true,
    },
    {
      stringIcon: "🫵🏻",
      title: `Tap ${
        getNextLevel(ETask.TASK_CLICK_LEVEL, claimState?.task_click_level)
          ?.value || ""
      } times`,
      link: null,
      reward: getNextLevel(ETask.TASK_CLICK_LEVEL, claimState?.task_click_level)
        ?.reward,
      is_done: claimState?.task_click_level === MAX_CLICK_LEVEL,
      type: ETask.TASK_CLICK_LEVEL,
      btnLabel: "Follow W-Community",
      index: 8,
      category: "In-game",
      showProbability: 100,
      isReadyToClaim:
        (readyToClaim as Record<ETask.TASK_CLICK_LEVEL, boolean>)
          ?.task_click_level || false,
      openPageBeforeTaskReadyType: "/claim",
      claimTask: true,
    },
    {
      claimTask: true,
      componentIcon: (
        <SmallCoin
          width={BALANCE_COIN_SIZE_X_LARGE}
          height={BALANCE_COIN_SIZE_X_LARGE}
        />
      ),
      title: `Earn ${
        getNextLevel(ETask.TASK_BALANCE_LEVEL, claimState?.task_balance_level)
          ?.value || ""
      } W-Coin`,
      link: null,
      reward: getNextLevel(
        ETask.TASK_BALANCE_LEVEL,
        claimState?.task_balance_level
      )?.reward,
      is_done: claimState?.task_balance_level === MAX_BALANCE_LEVEL,
      type: ETask.TASK_BALANCE_LEVEL,
      btnLabel: "Follow W-Community",
      index: 10,
      category: "In-game",
      showProbability: 100,
      isReadyToClaim:
        (readyToClaim as Record<ETask.TASK_BALANCE_LEVEL, boolean>)
          ?.task_balance_level || false,
      openPageBeforeTaskReadyType: "/claim",
    },
    // {
    //   icon: wcoin7daysIcon,
    //   title: "Add \"W-Coin\" to your",
    //   subtitle: "Telegram Last Name",
    //   link: null,
    //   reward: 70_000,
    //   // @ts-ignore
    //   is_done: claimState?.tasks?.task_wcoin_in_nickname_visit_every_day_new?.days === 7 || false,
    //   type: ETask.TASK_WCOIN_IN_NICKNAME_VISIT_EVERY_DAY,
    //   btnLabel: "Follow W-Community",
    //   index: 6,
    //   isReadyToClaim: (
    //     readyToClaim as Record<ETask.TASK_WCOIN_IN_NICKNAME_VISIT_EVERY_DAY, boolean>
    //   ).task_wcoin_in_nickname_visit_every_day_new,
    //   // @ts-ignore
    //   additionalRewardInfo: `Day ${claimState?.tasks?.task_wcoin_in_nickname_visit_every_day_new?.days || 0}/7`,
    //   claimTask: true,
    //   category: "In-game",
    // },
    ...formattedNewTasks,
  ]
    .filter((i) => i)
    .reduce((acc, task) => {
      // @ts-ignore
      if (task.isReadyToClaim === true) {
        // @ts-ignore
        acc.unshift(task);
      } else {
        // @ts-ignore
        acc.push(task);
      }
      return acc;
    }, [] as ITask[]);

  const currentTask = React.useMemo(() => {
    if (currentIndexTask) {
      return TASKS.find((i) => i.type === currentIndexTask) || null;
    }

    return null;
  }, [TASKS, currentIndexTask]);

  const checkedLevels = () => {
    if (claimState?.task_visit_every_day?.checkedLevels?.length === 0) {
      return [claimState?.task_visit_every_day?.currentLevel || 0];
    }

    return [
      ...(claimState?.task_visit_every_day?.checkedLevels || []),
      claimState?.task_visit_every_day?.currentLevel || 0,
    ];
  };

  const handleClaimTask = async (ct: ETask | null) => {
    const task = TASKS.find((i) => i.type === ct) || null;
    impactOccurred("heavy");

    const payPassMultiplier = user?.claim_state?.payPassMultiplier;

    if (task !== null && payPassMultiplier) {
      let newData = {
        [task.type]: true,
        balance:
          (user.claim_state?.balance || 0) + task!.reward * payPassMultiplier,
        balance_from_clicks:
          (user.claim_state?.balance_from_clicks || 0) +
          task!.reward * payPassMultiplier,
      };

      if (task.type === ETask.TASK_TRY_STAKING_CLAIMED) {
        impactOccurred("heavy");
        setCurrentIndexTask(ct);

        newData[task.type] =
          (claimState?.task_stake_level || 0) + 1 === MAX_TASK_TRY_STAKING_LEVEL
            ? true
            : false;
        newData["task_stake_level"] = (claimState?.task_stake_level || 0) + 1;
      }

      if (task.type === ETask.TASK_CLICK_LEVEL) {
        impactOccurred("heavy");
        setCurrentIndexTask(ct);
        newData[task.type] = (claimState?.task_click_level || 0) + 1;
      }

      if (task.type === ETask.TASK_BALANCE_LEVEL) {
        impactOccurred("heavy");
        setCurrentIndexTask(ct);
        newData[task.type] = (claimState?.task_balance_level || 0) + 1;
      }

      if (task.type === ETask.TASK_VISIT_EVERY_DAY) {
        impactOccurred("heavy");
        setCurrentIndexTask(ct);

        const newTaskVisitEveryDay = {
          last_visited: getCurrentTimestamp(),
          currentLevel:
            (claimState?.task_visit_every_day?.currentLevel || 0) + 1,
          checkedLevels: checkedLevels(),
        };

        // @ts-ignore
        newData[task.type] = newTaskVisitEveryDay;
      }

      if (task?.isJSONTask) {
        if (task?.type === ADSGRAM_FIELD && task.type) {
          // @ts-ignore
          const viewedTimes = hasDayPassedSince(
            claimState?.tasks?.[task.type]?.lastViewed
          )
            ? 1
            : (claimState?.tasks?.[task.type]!.viewedTimes || 0) + 1;
          // @ts-ignore
          const lastViewed = updateDateIfNeeded(
            claimState?.tasks?.[task.type]?.lastViewed
          );

          newData = {
            ...newData,
            // @ts-ignore
            tasks: {
              ...(claimState?.tasks || {}),
              // @ts-ignore
              [task.type]: {
                viewedTimes,
                lastViewed,
              },
            },
          };
        } else if (task?.type === "inviteThreeMatesData") {
          newData = {
            ...newData,
            // @ts-ignore
            tasks: {
              ...(claimState?.tasks || {}),
              // @ts-ignore
              [task.type]: {
                // @ts-ignore
                mates: claimState?.tasks?.inviteThreeMatesData?.mates,
                isEnoughMates: true,
                completed: true,
              },
            },
          };
        } else {
          newData = {
            ...newData,
            // @ts-ignore
            tasks: claimState?.tasks
              ? { ...claimState.tasks, [task.type]: true }
              : { [task.type]: true },
          };
        }
      }

      if (task.type === ETask.TASK_WCOIN_IN_NICKNAME_VISIT_EVERY_DAY) {
        newData = {
          ...newData,
          // @ts-ignore
          tasks: claimState?.tasks
            ? {
                ...claimState.tasks,
                [task.type]: {
                  // @ts-ignore
                  days: claimState?.tasks
                    ?.task_wcoin_in_nickname_visit_every_day_new?.days
                    ? claimState?.tasks
                        ?.task_wcoin_in_nickname_visit_every_day_new?.days + 1
                    : 1,
                  timestamp: getCurrentTimestamp(),
                },
              }
            : {
                // @ts-ignore
                days: claimState?.tasks
                  ?.task_wcoin_in_nickname_visit_every_day_new?.days
                  ? claimState?.tasks
                      ?.task_wcoin_in_nickname_visit_every_day_new?.days + 1
                  : 1,
                timestamp: getCurrentTimestamp(),
              },
        };
      }

      if (
        task.hasTelegramLink &&
        !task.doubleVerify &&
        !task.verifyExternalLink &&
        !task.checkCode
      ) {
        webApp.openTelegramLink(task.link);
      } else if (
        task.link &&
        !task.doubleVerify &&
        !task.verifyExternalLink &&
        !task.checkCode
      ) {
        webApp.openLink(task.link);
      }

      updateUserExternalApi({ ...newData })
        .then(() => {
          // @ts-ignore
          setClaimState?.((prevState) => ({ ...prevState, ...newData }));

          if (task?.isJSONTask) {
            setCurrentIndexTask(ct);
          } else {
            if (task.type !== ETask.TASK_TRY_STAKING_CLAIMED) {
              setCurrentIndexTask(ct);
            }
          }
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  function handleChangeCurrentIndexTask(ct: ETask | null) {
    impactOccurred("heavy");
    setCurrentIndexTask(ct);
    const task = TASKS.find((i) => i.type === ct) || null;

    if (task) {
      setTimeout(() => {
        if (
          task?.verifyLink ||
          task?.doubleVerify ||
          task?.verifyExternalLink ||
          task?.checkCode
        ) {
          return;
        } else if (task.link) {
          webApp.openLink(task.link);
        }
      }, 500);
    }
  }

  const isJoinedToTelegram = (type?: ETask | string) => {
    if (type) {
      // @ts-ignore
      if (user?.claim_state && user.claim_state?.[type]) {
        return true;
      } else {
        return false;
      }
    } else {
      return user?.claim_state?.task_join_community_claimed;
    }
  };

  return {
    isLoading: isLoading || isTasksLoading,
    isSuccess: isTaskSuccess && isSuccess,
    user,
    tasks: TASKS,
    onChangeCurrentIndexTask: handleChangeCurrentIndexTask,
    currentTask,
    onClaimTask: handleClaimTask,
    isFetching: updateUserState.isLoading || state?.isFetching,
    WALLET_TASK,
    THREE_FRIENDS_TASK,
    readyToClaim,
    isJoinedToTelegram,
    onTelegramGroupVerify: handleTelegramGroupVerify,
    can_update_ads: (ADS_COUNT as number) === 3,
  };
};

export const useCollaborationNotification = () => {
  const webApp = useWebApp();

  const [openCollaboration, setOpenCollaboration] = React.useState(false);

  const handleOpenCollaboration = () => {
    setOpenCollaboration(true);
  };

  const handleCloseCollaboration = () => {
    setOpenCollaboration(false);
  };

  const handleOpenSupport = () => {
    webApp.openLink("https://forms.gle/ebaetPf6PJzEfu2Y8");
    handleCloseCollaboration();
  };

  return {
    openCollaboration,
    onOpenCollaboration: handleOpenCollaboration,
    onCloseCollaboration: handleCloseCollaboration,
    onOpenSupport: handleOpenSupport,
  };
};

export const useLocalPageTasksApi = (user: TAppUser, tasks: ITask[]) => {
  const [impactOccurred] = useHapticFeedback();

  const { openCollaboration, onCloseCollaboration, onOpenSupport } =
    useCollaborationNotification();

  const [activeTab, setActiveTab] = React.useState<ETab>(ETab.ACTIVE);

  const handleActiveTaskChange = (tab: ETab) => {
    impactOccurred("heavy");
    setActiveTab(tab);
  };

  function categorizeTasks(tasks: ITask[], isDone: boolean = false) {
    return tasks.reduce((acc, task) => {
      if (task.is_done !== isDone) {
        return acc;
      }

      const category = task.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(task);
      return acc;
    }, {} as { [key: string]: ITask[] });
  }

  const activeTasksList = categorizeTasks(tasks, false);

  const completedTasksList = categorizeTasks(tasks, true);

  const isCompletedTasksListEmpty = Object.keys(completedTasksList).every(
    (item) => completedTasksList[item]?.length === 0
  );

  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);

  const handleSetShowVerifyModal = (value: boolean) => {
    setShowVerifyModal(value);
  };

  const [showShareModal, setShowShareModal] = React.useState<boolean | null>(
    false
  );

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;
  const bgImage = React.useMemo(() => {
    switch (activeTab) {
      case ETab.BITGET_WALLET:
        return bitgetImageBg;
      case ETab.AZURE:
        return azureImageBg;
      case ETab.OKX:
        return okxImageBg;
      default:
        return bg;
    }
  }, [activeTab]);

  return {
    openCollaboration,
    onCloseCollaboration,
    onOpenSupport,
    isCompletedTasksListEmpty,
    activeTab,
    onActiveTaskChange: handleActiveTaskChange,
    activeTasksList,
    completedTasksList,
    showVerifyModal,
    handleSetShowVerifyModal,
    showShareModal,
    setShowShareModal,
    payPassMultiplier,
    bgImage,
  };
};
