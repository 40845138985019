import React from "react";

import "./index.scss";
import { Stack } from "@mui/material";

const coinIcon = require("./img/coin-icon.png") as string;
const batteryIcon = require("./img/battery-icon.png") as string;
const diceIcon = require("./img/dice-icon.png") as string;

const waiIcon = require("./img/wai-icon.png") as string;
const keyIcon = require("./img/key-icon.png") as string;
const galaxyIcon = require("./img/galaxy-icon.png") as string;

const tonIcon = require("./img/ton-icon.png") as string;

const hightContainer = [
  {
    icon: coinIcon,
    text: "W-Coin",
    width: 77.59,
    height: 80,
    color: "linear-gradient(to right, #8254BD, #C333E7)",
  },
  {
    icon: batteryIcon,
    text: "Battery",
    width: 62.59,
    height: 73.59,
    color: "linear-gradient(to right, #04BC0B, #C1FFB2)",
  },
  {
    icon: diceIcon,
    text: "Dice",
    width: 62.59,
    height: 66,
    color: "linear-gradient(to right, #E92626, #FCA2A2)",
  },
];

const lowContainer = [
  {
    icon: waiIcon,
    text: "WAI",
    width: 77.59,
    height: 81.51,
    color: "linear-gradient(to right, #8254BD, #C333E7)",
  },
  {
    icon: keyIcon,
    text: "W-Keys",
    width: 69.86,
    height: 81.51,
    color: "linear-gradient(to right, #fff, #fff)",
  },
  {
    icon: galaxyIcon,
    text: "W-Galaxy",
    width: 92.02,
    height: 65.87,
    color: "linear-gradient(to right, #fff, #fff)",
  },
];

export const Slide2: React.FC = () => {
  return (
    <div className="page-chest-onboarding-slide2-wrapper">
      <div className="page-chest-onboarding-slide2-header">
        <p className="page-chest-onboarding-slide2-header-hight">What can I</p>
        <p className="page-chest-onboarding-slide2-header-low ab">win?</p>
      </div>
      <div className="page-chest-onboarding-slide2-body">
        <div className="page-chest-onboarding-slide2-body-container">
          <div className="page-chest-onboarding-slide2-body-container-item">
            {hightContainer.map((item) => {
              return (
                <div
                  key={item.text}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    width: "92px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "74px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.icon}
                      width={item.width}
                      height={item.height}
                    />
                  </div>
                  <p
                    className="ab page-chest-onboarding-slide2-body-container-item-text"
                    style={{ fontSize: "17px", backgroundImage: item.color }}
                  >
                    {item.text}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="page-chest-onboarding-slide2-body-container-item">
            {lowContainer.map((item) => {
              return (
                <div
                  key={item.text}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    width: "92px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "81.51px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.icon}
                      width={item.width}
                      height={item.height}
                    />
                  </div>
                  <p
                    className="ab page-chest-onboarding-slide2-body-container-item-text"
                    style={{ fontSize: "17px", backgroundImage: item.color }}
                  >
                    {item.text}
                  </p>
                </div>
              );
            })}
          </div>
          <Stack
            width="100%"
            gap="8px"
            justifyContent="center"
            alignItems="center"
          >
            <img width="80px" src={tonIcon} alt="" />
            <p
              className="ab"
              style={{
                fontSize: "17px",
                color: "#1AC9FF",
              }}
            >
              TON
            </p>
          </Stack>
        </div>
      </div>
    </div>
  );
};
