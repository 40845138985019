import React from "react";

export const useInputCheckCode = () => {
    const [errorMessage, setErrorMessage] = React.useState<boolean | null>(null);
    const [inputCode, setInputCode] = React.useState<string | undefined>(
      undefined
    );

    const onInputChange = (value: string) => {    
        if (Boolean(value)) {
          setInputCode(value as string);
        } else {
          setInputCode(undefined);
        }
    
        if (errorMessage) {
          setErrorMessage(null);
        }
      };
    
      return {
        onInputChange,
        inputCode,
        setInputCode,
        setErrorMessage,
        errorMessage,
      }
}