export enum EAPITagType {
    USERS = 'USERS',
    LEADERBOARD ='LEADERBOARD',
    VALIDATE_USER_IN_GROUP = 'VALIDATE_USER_IN_GROUP',
    TASKS = 'TASKS',
    STATS = 'STATS',
    MATES_LIST = 'MATES_LIST',
    MATES_GET_REWARDS = 'MATES_GET_REWARDS',
    STAKE_CREATE = 'STAKE_CREATE',
    CLAIM_STAKING = 'CLAIM_STAKING',
    USER_PASSIVE_INCOME = 'USER_PASSIVE_INCOME',
    USER_PASSIVE_INCOME_CLAIM = 'USER_PASSIVE_INCOME',
    USER_PASSIVE_INCOME_INCREASE_LEVEL = 'USER_PASSIVE_INCOME_INCREASE_LEVEL',
    WAI_LEADERBOARD = 'WAI_LEADERBOARD',
    NOTIFY_USERS= 'NOTIFY_USERS',
    LEGENDS = 'LEGENDS',
    EXTERNAL_API = 'EXTERNAL_API',
    EXTERNAL_API_UPDATE_USER = 'EXTERNAL_API_UPDATE_USER',
    PAY_PASS_GENERATE_INVOICE = 'PAY_PASS_GENERATE_INVOICE',
    OKX_CHECK_UID = 'OKX_CHECK_UID',
    // CHESTS
    CHESTS = 'CHESTS',
    OPEN_ORDER = 'OPEN_ORDER',
    OPEN_STARS_ORDER = 'OPEN_STARS_ORDER',
    CLOSE_ORDER = 'OPEN_ORDER',
}