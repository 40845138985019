import React from "react";

import "./index.scss";

const countdownBg = require("./img/countdown-bg.png") as string;

interface IProps {
  onOpen: () => void;
}

export const FinishStackingCountdown: React.FC<IProps> = ({ onOpen }) => {
  const targetDate = new Date(Date.UTC(2024, 11, 20));

  const calculateDaysLeft = () => {
    const now = new Date();
    const timeDifference = targetDate.getTime() - now.getTime();
    return Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const timeDifference = targetDate.getTime() - now.getTime();

    const totalSeconds = Math.max(0, Math.floor(timeDifference / 1000));
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  };

  const [daysLeft, setDaysLeft] = React.useState(calculateDaysLeft);
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft);
  const [isCountdownComplete, setIsCountdownComplete] = React.useState(false);

  React.useEffect(() => {
    const updateCountdown = () => {
      const newDaysLeft = calculateDaysLeft();
      const newTimeLeft = calculateTimeLeft();

      setDaysLeft(newDaysLeft);
      setTimeLeft(newTimeLeft);

      if (
        newDaysLeft === 0 &&
        newTimeLeft.hours === "00" &&
        newTimeLeft.minutes === "00" &&
        newTimeLeft.seconds === "00"
      ) {
        setIsCountdownComplete(true);
      }
    };

    const timeUntilNextSecond = 1000 - new Date().getMilliseconds();

    const timer = setTimeout(updateCountdown, timeUntilNextSecond);

    return () => clearTimeout(timer);
  }, [daysLeft, timeLeft]);

  if (isCountdownComplete) {
    return null;
  }

  return (
    <div
      className="countdown wai-button"
      style={{ backgroundImage: `url(${countdownBg})` }}
      onClick={onOpen}
    >
      <p className="ab">
        {daysLeft > 1 ? (
          <>
            <p>Tapping ends in</p>
            <p className="countdown-days">{daysLeft}</p>
            <p>{daysLeft === 1 ? "day" : "days"}</p>
          </>
        ) : (
          <>
            <p>Tapping closes in</p>
            <p className="countdown-hours">
              {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </p>
          </>
        )}
      </p>
    </div>
  );
};
