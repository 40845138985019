import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { PAY_PAY_REWARD, PAY_PAY_REWARD_LIGHT } from "../consts";
import { formatValues } from "../../../utils";

const rewardsIcon = require("./img/all-rewards-icon.png") as string;
const unlockIcon = require("./img/unlock-icon.png") as string;
const oneTimeRewardIcon = require("./img/one-time-reward-item.png") as string;
const waiIcon = require("./img/2-x-wai-icon.png") as string;
const eventIcon = require("./img/events-icon.png") as string;
const groupIcon = require("./img/group-icon.png") as string;
const checkedIcon = require("./img/checked-icon.png") as string;
const protectionIcon = require("./img/protection.png") as string;

const ACCORDION_ITEMS = [
  {
    icon: rewardsIcon,
    title: "3x on All Rewards",
    description:
      "Earn triple rewards for all activities, including tasks, boosters, taps and inviting Mates!",
    style: "accordion-first-section",
  },
  {
    icon: unlockIcon,
    title: "100% Unlock on TGE",
    description:
      "Get full access to tokens at TGE launch, with W-Galaxy mates receiving theirs first.",
  },
  {
    icon: oneTimeRewardIcon,
    title: "One-Time W-Coin Reward",
    description: `Receive a one-time reward of ${formatValues(
      PAY_PAY_REWARD
    )} W-Coin, which can be used for purchases and upgrades.`,
  },
  {
    icon: waiIcon,
    title: "2x $WAI Claim",
    description: "Double your Claim and collect $WAI faster than ever before.",
  },
  {
    icon: eventIcon,
    title: "Online/Offline Special Events",
    description:
      "Participate in exclusive events both in-game and offline for a chance to earn unique rewards!",
  },
  {
    icon: groupIcon,
    title: "Community Reward Pool",
    description:
      "Take part in exclusive prize draws for W-Galaxy subscribers—sign up today for a chance to win big!",
  },
  {
    icon: protectionIcon,
    title: "W-Burning protection",
    description:
      "Your W-Coin is protected from the burning mechanism, and its safety is guaranteed until the airdrop.",
  },
  {
    title: "· · · coming soon ···",
    style: "accordion-last-section",
    isComingSoon: true,
  },
];

const ACCORDION_ITEMS_LIGHT = [
  {
    icon: rewardsIcon,
    title: "2x on All Rewards",
    description:
      "Earn double rewards for all activities, including boosters, taps, and inviting Mates!",
    style: "accordion-first-section",
    isComingSoon: false,
  },

  {
    icon: oneTimeRewardIcon,
    title: "One-Time W-Coin Reward",
    description: `Receive a one-time reward of ${formatValues(
      PAY_PAY_REWARD_LIGHT
    )} W-Coin, which can be used for purchases and upgrades.`,
    isComingSoon: false,
    style: "accordion-last-section-light",
  },
];

interface IProps {
  isPayPassPurchased: boolean;
  isActiveTabPayPassComplete: boolean;
}

export const AccordionComponent: React.FC<IProps> = ({
  isPayPassPurchased,
  isActiveTabPayPassComplete,
}) => {
  return (
    <div className="accordion-root">
      <Accordion allowMultipleExpanded={false} allowZeroExpanded>
        {(isActiveTabPayPassComplete
          ? ACCORDION_ITEMS
          : ACCORDION_ITEMS_LIGHT
        ).map((item, index) => (
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton className={`accordion-button ${item.style}`}>
                <span className="accordion-desc-item">
                  {!item.isComingSoon && (
                    <>
                      {isPayPassPurchased ? (
                        <img src={checkedIcon} alt="" />
                      ) : (
                        <img src={item.icon} alt="" />
                      )}
                      {item.title}
                    </>
                  )}
                  {item.isComingSoon && (
                    <span className="ab">{item.title}</span>
                  )}
                </span>
                {!item.isComingSoon && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="accordion-icon"
                  />
                )}
              </AccordionItemButton>
            </AccordionItemHeading>
            {!item.isComingSoon && (
              <AccordionItemPanel>
                <p>{item.description}</p>
              </AccordionItemPanel>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
