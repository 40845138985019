import React from "react";

import { motion } from "framer-motion";

import { ETab } from "../type";

import "./index.scss";

interface IProps {
  activeTab: ETab;
  onChange: (tab: ETab) => void;
  isCampaign: boolean;
}

export const ToggleGroup: React.FC<IProps> = ({
  activeTab,
  onChange,
  isCampaign,
}) => {
  const campaignFont = isCampaign ? "stroke-font" : "";

  return (
    <div
      className="boosters-toggle-wrapper"
      style={{ padding: "8px", overflowX: "auto", overflowY: "hidden" }}
    >
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.AZURE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.AZURE)}
      >
        <b className={campaignFont}>Azur Games</b>
        {activeTab === ETab.AZURE && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.OKX ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.OKX)}
      >
        <b className={campaignFont} style={{ fontSize: "16px" }}>
          OKX
        </b>
        {activeTab === ETab.OKX && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
        <h2
          className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
            activeTab === ETab.BITGET_WALLET ? "selected" : ""
          }`}
          onClick={() => onChange(ETab.BITGET_WALLET)}
        >
          <b className={campaignFont}>Bitget Wallet</b>
          {activeTab === ETab.BITGET_WALLET && (
            <motion.div
              className={`underline ${isCampaign ? "underline-bg" : ""}`}
              layoutId="underline"
            />
          )}
        </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.ACTIVE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.ACTIVE)}
      >
        <b className={campaignFont}>Active</b>
        {activeTab === ETab.ACTIVE && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.COMPLETED ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.COMPLETED)}
      >
        <b className={campaignFont}>Completed</b>
        {activeTab === ETab.COMPLETED && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
    </div>
  );
};
