import React from "react";

import { formatValues } from "../../../utils";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../consts";
import { TMates } from "../../feature/users/models";

import "./index.scss";

interface IProps extends React.PropsWithChildren {
  mates?: TMates;
  onGetClaimAll: () => void;
  handleRefetchUsers: () => void;
}

export const ListWrapper: React.FC<IProps> = ({
  mates,
  onGetClaimAll,
  children,
  handleRefetchUsers,
}) => {
  const hasMates = mates?.count_mates;

  return (
    <div className="list-root">
      <div className="list-title-wrapper">
        <h2 className="list-title green-color">
          <b>My Mates {mates?.count_mates ? `(${mates?.count_mates})` : ""}</b>
        </h2>
        <div className="mates-invite-button-wrapper">
          {hasMates ? (
            <button
              onClick={onGetClaimAll}
              className={`${
                !Boolean(mates?.total_reward_balance) && "btn-disabled"
              } mates-invite-button ab`}
            >
              Claim all
            </button>
          ) : (
            <button
              className=" mates-invite-button ab"
              onClick={handleRefetchUsers}
            >
              Refresh
            </button>
          )}
        </div>
      </div>
      {!!hasMates && (
        <div className="total-rewards-wrapper">
          <p className="total-rewards font-size-15 common-color">
            Total amount:
            <SmallCoin
              width={BALANCE_COIN_SIZE_SMALL}
              height={BALANCE_COIN_SIZE_SMALL}
            />
            {formatValues(mates?.total_reward_balance)}
          </p>
        </div>
      )}
      {hasMates ? (
        <div className="list-wrapper">{children}</div>
      ) : (
        <div className="mates-list-empty common-color">
          <p>The mates list is empty.</p>
        </div>
      )}
    </div>
  );
};
