export enum ECampaign {
  VISIT_BITGET_WALLET = "VISIT_BITGET_WALLET",
  VISIT_BITGET_WALLET_IN_TG = "VISIT_BITGET_WALLET_IN_TG",
  JOIN_BITGET_IN_X = "JOIN_BITGET_IN_X",
}

export interface ICampaign {
    icon: string;
    link: string;
    type: ECampaign;
    title: string;
    reward: number;
    connection?: boolean;
    externalLink?: boolean;
    internalLink?: boolean;
    is_done?: boolean;
}

export interface ClaimTaskProps {
  task: ICampaign;
}