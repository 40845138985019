export enum EChestNames {
    STARTER = 'STARTER',
    GUARDIAN = 'GUARDIAN',
    TREASURE = 'TREASURE',
    WCOSMIC = 'W-COSMIC',
}

export type TChest = {
    name: string;
    amount: number;
    icon: string;
    iconOpened: string
    isBestDeal: boolean;
    type: EChestNames;
}

export enum EModalType  {
    DETAILS = 'details',
    KEY_SHOP = 'key_shop',
    HISTORY = 'history',
    REWARD = 'reward',
    CHECK_STANSACTION = 'check_transaction'
}

export enum EKeyType {
    Bronze = 'keys_1',
    Silver = 'keys_10',
    Gold = 'keys_100',
    Platinum = 'keys_1000',
}
export type TKey = {
    type: EKeyType;
    amount: number;
    isBestDeal: boolean;
    ton: number;
    stars: number;
}

export enum ERewardType {
    "wcoin" = "wcoin",
    "battery" = "battery",
    "dice" = "dice",
    "wai" = "wai",
    "keys" = "keys",
    "pay-pass-lite" = "pay-pass-lite",
    "pay-pass-complete" = "pay-pass-complete",
}

export type TChestsHistory = {
    chest_product_id: EKeyType;
    date: number;
    reward_type: string;
    reward_value: string;
}