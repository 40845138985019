import React from "react";

import { CSSTransition } from "react-transition-group";

const modalImg = require("./img/staking-close-icon.png") as string;

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const CloseStakingModal: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <>
      {show && (
        <div className={`overlay app-modal-root ${show ? "blur" : ""}`}></div>
      )}
      <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
        <div className="overlay app-modal-root">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="close-modal ab" onClick={onClose}>
              x
            </div>
            <div className="app-modal-content task-modal-content">
              <div className="task-modal-desc">
                <div className="" style={{ background: "transparent" }}>
                  <img src={modalImg} style={{ width: "72px" }} alt="" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ color: "#fff" }}>
                    <p className="ab">🎉 Staking is Complete! 🎉</p>
                  </p>
                  <div style={{ color: "#fff" }}>
                    <p>
                      <p>
                        Staking phase has officially ended. You can now view
                        your staking history in the “Complete” tab and check
                        overall staking statistics in the “Stats” tab
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="task-modal-button-wrapper">
                <button onClick={onClose} className="app-modal-content-button">
                  Great
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
