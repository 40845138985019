import React from "react";

import { Box, Button, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IPendingOrder } from "../../../../feature/users/models";
import { ECurrentPurchaseType } from "../models";
import { TKey } from "../../../models";

const starsIcon = require("../actions-buttons/img/stars-icon.png") as string;

interface IProps {
  onCloseModal: () => void;
  onCloseOrder: () => void;
  openedTonOrders?: IPendingOrder[];
  openedStarsOrders?: IPendingOrder[];
  onCheckTransactionKeys: (transactionId: string) => Promise<void> | undefined;
  transactionId?: string | null;
  inputCode?: string;
  onInputChange: (value: string) => void;
  errorMessage: boolean | null;
  currentPurchaseType: ECurrentPurchaseType | null;
  currentKey: TKey | null;
}

export const CheckTransaction: React.FC<IProps> = ({
  onCloseModal,
  onCloseOrder,
  openedTonOrders,
  openedStarsOrders,
  onCheckTransactionKeys,
  transactionId,
  inputCode,
  onInputChange,
  errorMessage,
  currentPurchaseType,
  currentKey,
}) => {
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const isOrderOpened = !!(
    openedTonOrders?.length || openedStarsOrders?.length
  );

  const [cancelNotification, setCancelNotification] = React.useState(0);

  const isCurrentPurchaseTypeStars =
    currentPurchaseType === ECurrentPurchaseType.STARS;

  return (
    <Stack
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <IconButton
        onClick={() => {
          onInputChange("");
          onCloseModal();
        }}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#fff",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Stack gap="8px">
        <p
          className="ab"
          style={{
            fontSize: "140px",
            color: "#fff",
            margin: "0 auto",
            marginBottom: "20px",
          }}
        >
          #
        </p>
        <p
          className="ab"
          style={{ fontSize: "35px", color: "#fff", margin: "0 auto" }}
        >
          Payment error
        </p>
        {!isCurrentPurchaseTypeStars && (
          <p
            style={{
              fontSize: "16px",
              color: "#fff",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            Input your transaction hash to check your payment status
          </p>
        )}
        <Box
          className="okx-input-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "32px",
          }}
        >
          {isOrderOpened && !isCurrentPurchaseTypeStars && (
            <>
              <Stack gap={1} width="100%">
                <input
                  value={inputCode}
                  type="text"
                  className="okx-mobile-input"
                  placeholder="Input hash here"
                  onChange={(e) => onInputChange(e.target.value as string)}
                  onBlur={scrollToBottom}
                  style={{
                    color: "#fff",
                    height: "46px",
                    borderRadius: "22px",
                  }}
                />
                <p
                  className={`azure-games-validation-label ${
                    errorMessage && "azure-games-validation-label-visible"
                  }`}
                  style={{ fontSize: "16px" }}
                >
                  {errorMessage && "invalid hash"}
                </p>
              </Stack>
              <Button
                onClick={() =>
                  onCheckTransactionKeys(transactionId || inputCode || "")
                }
                className={`ab ${!inputCode && "btn-disabled"}`}
                style={{
                  minWidth: "110px",
                  borderRadius: "22px",
                  background: "#9747FF",
                  color: "#fff",
                  height: "46px",
                  textTransform: "none",
                }}
              >
                Check
              </Button>
            </>
          )}
        </Box>
      </Stack>
      <Stack gap={1} justifyContent="center" width="100%">
        <Stack
          flexDirection="row"
          textAlign="center"
          gap={1}
          justifyContent="center"
          width="100%"
        >
          <p
            style={{
              fontSize: "18px",
              color: "#fff",
            }}
          >
            Order info:{" "}
          </p>
          <Stack alignItems="center" flexDirection="row" gap={0.5}>
            <p
              style={{
                fontSize: "18px",
                color: "#fff",
              }}
            >
              {isCurrentPurchaseTypeStars ? currentKey?.stars : currentKey?.ton}{" "}
            </p>
            {isCurrentPurchaseTypeStars ? (
              <img src={starsIcon} width="15px" />
            ) : (
              <p
                style={{
                  fontSize: "18px",
                  color: "#fff",
                }}
              >
                TON
              </p>
            )}
          </Stack>
        </Stack>
        {/* <Button
          className={`ab ${isOrderOpened ? "" : "btn-disabled"}`}
          sx={{
            color: "#fff",
            width: "100%",
            height: "54px",
            backgroundColor: "red",
            borderRadius: "22px",
            fontSize: "16px",
            textTransform: "none",
          }}
          onClick={
            cancelNotification === 0
              ? () => setCancelNotification(1)
              : () => {
                  onInputChange("");
                  onCloseOrder();
                }
          }
        >
          {cancelNotification === 0 ? " Cancel Payment" : "Sure?"}
        </Button> */}
      </Stack>
    </Stack>
  );
};
