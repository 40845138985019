import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-loader-spinner";

import "./index.scss";
import { Stack } from "react-bootstrap";
import { motion } from "framer-motion";

// images
const logo = require("./img/logo.png") as string;
const text = require("./img/text.png") as string;
const tgIcon = require("../../img/tgIcon.png") as string;
const x_social = require("../../img/x.png") as string;
const chest1 = require("./img/chest1.png") as string;
const chest2 = require("./img/chest2.png") as string;
const chest3 = require("./img/chest3.png") as string;
const chest4 = require("./img/chest4.png") as string;

interface IProps {
  onOpenTelegram: () => void;
  onOpenX: () => void;
}

const chests = [
  { src: chest1, width: 26.37, height: 25.75 },
  { src: chest2, width: 35.24, height: 33.2 },
  { src: chest3, width: 47.99, height: 42.68 },
  { src: chest4, width: 69.44, height: 66.41 },
];

export const ChestsLoader: React.FC<IProps> = ({ onOpenTelegram, onOpenX }) => {
  const [visibleChests, setVisibleChests] = useState<number[]>([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (reset) {
      const resetTimer = setTimeout(() => {
        setVisibleChests([]);
        setReset(false);
      }, 500);
      return () => clearTimeout(resetTimer);
    }

    if (visibleChests.length < chests.length) {
      const timer = setTimeout(() => {
        setVisibleChests((prev) => [...prev, prev.length]);
      }, 300);
      return () => clearTimeout(timer);
    }

    if (visibleChests.length === chests.length) {
      const hideTimer = setTimeout(() => {
        setReset(true);
      }, 300);
      return () => clearTimeout(hideTimer);
    }
  }, [visibleChests, reset]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          alignItems: "center",
          color: "#fff",
          marginTop: "30px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "40px",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack gap={3} style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "57px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  width: "200px",
                  height: "209px",
                }}
              >
                <img width="200px" height="209px" src={logo} alt="" />
              </div>
            </div>
            <p className="loader-text">Last steps of mining phase</p>
          </Stack>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              flexDirection: "column",
            }}
          >
            <Stack
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                height: "66.41",
                alignItems: "baseline",
                gap: "8px",
                paddingLeft: "14px",
              }}
            >
              {chests.map((chest, index) => (
                <motion.img
                  key={index}
                  src={chest.src}
                  width={`${chest.width}px`}
                  height={`${chest.height}px`}
                  alt=""
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: visibleChests.includes(index) ? 1 : 0,
                  }}
                  transition={{
                    duration: 0.5,
                  }}
                  style={{
                    display: "block",
                  }}
                />
              ))}
            </Stack>
            <div
              style={{
                borderRadius: "20px",
                border: "2px solid #fff",
                padding: "6px 12px",
                justifyContent: "space-around",
                display: "flex",
                gap: "8px",
                width: "78px",
              }}
            >
              <img
                style={{ color: "#fff" }}
                width={18}
                height={15}
                src={tgIcon}
                alt=""
                onClick={onOpenTelegram}
              />
              <div
                style={{
                  height: "15px",
                  color: "#fff",
                  width: "1px",
                  backgroundColor: "#fff",
                }}
              ></div>
              <img
                style={{ color: "#fff" }}
                width={15}
                height={15}
                src={x_social}
                alt=""
                onClick={onOpenX}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
