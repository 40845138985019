import React, { useState } from "react";

import "./index.scss";

import { Slide1, Slide2, Slide3 } from "./slides";

const closeOnboardingIcon = require("./img/ok.png") as string;
const nextArrowIcon = require("./img/arrow_onboard.svg").default as string;

interface IProps {
  onClose: () => void;
}

export const Carousel: React.FC<IProps> = ({ onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [<Slide1 />, <Slide2 />, <Slide3 />];

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleCloseOnboarding = () => {
    onClose();
  };

  return (
    <div
      className="carousel overlay app-modal-root blur"
      style={{ position: "relative" }}
    >
      {currentIndex ? (
        <div className="close-modal ab" onClick={onClose}>
          x
        </div>
      ) : null}

      {currentIndex !== 0 ? (
        <button className="carousel-button prev" onClick={handlePrevClick}>
          <img
            width="15px"
            height="30px"
            src={nextArrowIcon}
            alt=""
            style={{ rotate: "200grad", border: "unset" }}
          />
        </button>
      ) : null}
      <div className="carousel-slide">{slides[currentIndex]}</div>
      {currentIndex === 2 ? null : (
        <button
          className="carousel-button next"
          onClick={handleNextClick}
          style={{ border: "unset" }}
        >
          <img width="15px" height="30px" src={nextArrowIcon} alt="" />
        </button>
      )}
    </div>
  );
};
