import React from "react";

import { ChestsLoader } from "./chests-loader";

interface IProps {
  onOpenTelegram: () => void;
  onOpenX: () => void;
  bg?: string;
}

export const MainLoader: React.FC<IProps> = ({
  onOpenTelegram,
  onOpenX,
  bg,
}) => {
  // return <BitgetLoader onOpenTelegram={onOpenTelegram} onOpenX={onOpenX} />
  return <ChestsLoader onOpenTelegram={onOpenTelegram} onOpenX={onOpenX} />;

  //   <ChooseLoader onOpenTelegram={onOpenTelegram} onOpenX={onOpenX} bg={bg} />
};
