import React from "react";

import { toast } from "react-toastify";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";


import { UserContext } from "../contexts/user-context";
import {
  useGetWaiQuery,
  useLazyGetWaiClaimQuery,
  useLazyGetWaiIncreaseLevelQuery,
} from "../feature/users";
import { useLazyGetWaiLeaderboardByLeagueQuery } from "../feature/users";
import { TLeague } from "../feature/users/wai.models";
import { LottieAnimationContext } from "../contexts/lottie-animation-context";
import { BG_FROM_LS_STATE } from "../../consts";
import { IUserPassiveIncomeData } from "../feature/users/models";

import "./index.scss";
import { EWaiUpgradeType, TLeagueData } from "./models";
import { DEFAULT_LEAGUE_DATA, SELECTED_UPGRADE_DATA } from "./consts";

export const useTimerApi = (currentTradeSessionStartAt: number | null) => {
  const [remainingTime, setRemainingTime] = React.useState<null | string>(null);
  const [canClaim, setCanClaim] = React.useState(false);

  React.useEffect(() => {
    if (currentTradeSessionStartAt && !canClaim) {
      const startTime = currentTradeSessionStartAt;

      const interval = setInterval(() => {
        const now = Date.now();
        const elapsedTime = now - startTime;
        const eightHoursInMilliseconds = 8 * 60 * 60 * 1000;
        const remainingTime = eightHoursInMilliseconds - elapsedTime;

        if (remainingTime <= 0) {
          setRemainingTime("0:00:00");
          setCanClaim(true);
          clearInterval(interval);
        } else {
          const hours = Math.floor(remainingTime / (1000 * 60 * 60));
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          const formattedTime = `${hours}:${String(minutes).padStart(
            2,
            "0"
          )}:${String(seconds).padStart(2, "0")}`;
          setRemainingTime(formattedTime);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [currentTradeSessionStartAt, canClaim]);

  return {
    remainingTime,
    canClaim,
    setCanClaim,
  };
};

export const useWaiApi = () => {
  const [selectedUpgradeType, setSelectedUpgradeType] =
    React.useState<EWaiUpgradeType | null>(null);
  const [waiData, setWaiData] = React.useState<
    IUserPassiveIncomeData | undefined
  >();
  const [impactOccurred] = useHapticFeedback();

  const [showConfetti, setShowConfetti] = React.useState(false);

  const { isLoading, user } = React.useContext(UserContext);
  const { setIsVisible } = React.useContext(LottieAnimationContext);

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;

  const { data, isLoading: isWaiLoading, isFetching } = useGetWaiQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [getClaim, stateClaim] = useLazyGetWaiClaimQuery();
  const [getIncreaseLevel, stateIncreaseLevel] =
    useLazyGetWaiIncreaseLevelQuery();

  const [showLeague, setShowLeague] = React.useState(false);
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [showFirstTimeOnboarding, setShowFirstTimeOnboarding] =
    React.useState(false);
  const [showDynamicBg, setShowDynamicBg] = React.useState(false);

  function getIsBgStatic(): boolean | null {
    const value = localStorage.getItem(BG_FROM_LS_STATE);
    return value !== null ? JSON.parse(value) : null;
  }

  function setIsBgStatic(value: boolean): void {
    localStorage.setItem(BG_FROM_LS_STATE, JSON.stringify(value));
  }

  React.useEffect(() => {
    try {
      const bgStateFromLs = getIsBgStatic();

      if (bgStateFromLs !== null && bgStateFromLs === true) {
        setShowDynamicBg(true);
      } else {
        setShowDynamicBg(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    if (
      waiData?.current_trade_session?.started_at === null &&
      !showFirstTimeOnboarding
    ) {
      setShowOnboarding(true);
    }
  }, [waiData, showOnboarding, showFirstTimeOnboarding]);

  const { remainingTime, canClaim, setCanClaim } = useTimerApi(
    waiData?.current_trade_session?.started_at || null
  );

  React.useEffect(() => {
    if (data?.data) {

      setWaiData(data.data);
    }
  }, [data?.data]);

  const handleSelectUpgradeType = (type: EWaiUpgradeType | null) => {
    setSelectedUpgradeType(type);
  };

  const handleClaim = () => {
    getClaim()
      .unwrap()
      .then((res) => {
        if (res.data) {
          impactOccurred("heavy");

          setWaiData(res.data);
          setCanClaim(false);
        }
      })
      .catch((e) => console.warn(e));
  };

  const handleIncreaseLevel = (type: EWaiUpgradeType) => {
    getIncreaseLevel({ type })
      .unwrap()
      .then((res) => {
        if (res.data) {
          impactOccurred("heavy");
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 4000);

          if (selectedUpgradeType) {
            toast(
              `${SELECTED_UPGRADE_DATA[selectedUpgradeType].title} upgraded to level ${res.data?.features[selectedUpgradeType].current_level}`,
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                type: "success",
              }
            );

            if (res.data.league.name !== waiData?.league.name) {
              toast(`You have joined the ${res.data.league.name} league`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                type: "success",
              });
            }
          }

          setWaiData(res.data);
          if (canClaim) {
            setCanClaim(false);
          }
        }
      })
      .catch((e) => console.warn(e));
  };

  const handleDynamicBgToggle = () => {
    try {
      setShowDynamicBg((prevState) => !prevState);
      setIsBgStatic(!showDynamicBg);
    } catch (e) {
      console.log(e);
    }
  };

  const isDataLoading = isLoading || isWaiLoading || !waiData;
  const isDataFetching =
    stateClaim.isLoading ||
    stateClaim.isFetching ||
    stateIncreaseLevel.isLoading ||
    stateIncreaseLevel.isFetching;

  // @ts-ignore
  const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  return {
    showConfetti,
    setShowFirstTimeOnboarding,
    showLeague,
    setShowLeague,
    remainingTime,
    canClaim,
    onSelectUpgradeType: handleSelectUpgradeType,
    onClaim: handleClaim,
    onIncreaseLevel: handleIncreaseLevel,
    onDynamicBgToggle: handleDynamicBgToggle,
    isDataLoading,
    isDataFetching,
    telegramId,
    selectedUpgradeType,
    waiData,
    showDynamicBg,
    showOnboarding,
    setShowOnboarding,
    setIsVisible,
    payPassMultiplier,
    isFetching,
  };
};

export const useWaiLeaderboardApi = () => {
  const [getLeaderboard, stateLeaderboard] =
    useLazyGetWaiLeaderboardByLeagueQuery();

  const [leagueData, setLeagueData] =
    React.useState<TLeagueData>(DEFAULT_LEAGUE_DATA);

  const [currentModalLeague, setCurrentModalLeague] =
    React.useState<null | TLeague>(null);

  const handleGetWaiLeaderboardByLeague = (league: TLeague) => {
    setCurrentModalLeague(league);
    if (!leagueData[league].data) {
      getLeaderboard({ league })
        .unwrap()
        .then((res) => {
          const newData = { ...leagueData };
          // @ts-ignore
          newData[league].data = res.data;
          setLeagueData(newData);
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  };

  return {
    onGetWaiLeaderboardByLeague: handleGetWaiLeaderboardByLeague,
    isLeaderboardLoading:
      stateLeaderboard.isLoading || stateLeaderboard.isFetching,
    leagueData,
    currentModalLeague,
  };
};
