import React from "react";

import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { EWaiUpgradeType } from "../models";
import { ROUTES } from "../../routers";
import { TLeague } from "../../feature/users/wai.models";
import { IUserPassiveIncomeData } from "../../feature/users/models";

import "./index.scss";
import { Header } from "./header";
import { Balance } from "./balance";
import { CurrentLevelScores } from "./current-level-scores";
import { ActionButton } from "./action-button";
import { UpgradeButtons } from "./upgrade-buttons";

interface IProps {
  onChangeUpgradeType: (type: EWaiUpgradeType | null) => void;
  data: IUserPassiveIncomeData;
  onClaim: () => void;
  remainingTime: string | null;
  canClaim: boolean;
  selectedUpgradeType: EWaiUpgradeType | null;
  onModalLeagueOpen: () => void;
  onOnboardingModalOpen: () => void;
  showDynamicBg: boolean;
  onDynamicBgToggle: () => void;
  onGetWaiLeaderboardByLeague: (league: TLeague) => void;
  payPassMultiplier?: number;
  isFetching: boolean;
}

export const WaiContent: React.FC<IProps> = ({
  onChangeUpgradeType,
  data,
  onClaim,
  remainingTime,
  canClaim,
  selectedUpgradeType,
  onModalLeagueOpen,
  onOnboardingModalOpen,
  showDynamicBg,
  onDynamicBgToggle,
  payPassMultiplier,
  isFetching,
}) => {
  const [minItemSize, setMinItemSize] = React.useState<number | undefined>();

  const bodyRef = React.useRef<HTMLDivElement | null>(null);
  const offsetWidth = bodyRef.current?.offsetWidth;
  const offsetHeight = bodyRef.current?.offsetHeight;

  React.useEffect(() => {
    if (offsetWidth && offsetHeight) {
      const minItemSize = (Math.min(offsetWidth, offsetHeight) / 2) * 0.8;

      setMinItemSize(minItemSize);
    }
  }, [offsetWidth, offsetHeight, bodyRef]);


  return (
    <div className="wai-content-root">
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <div className="wai-content-item-header ">
        <Header
          onOnboardingModalOpen={onOnboardingModalOpen}
          showDynamicBg={showDynamicBg}
          onDynamicBgToggle={onDynamicBgToggle}
        />
        <Balance balance={data.balance} matesBalance={data.mates_balance} isFetching={isFetching} />
        <CurrentLevelScores
          rate={data.current_trade_session.rate}
          league={data.league.name}
          onModalLeagueOpen={onModalLeagueOpen}
        />
      </div>
      <div ref={bodyRef} className="wai-content-item-body">
        {minItemSize && (
          <UpgradeButtons
            data={data}
            onChange={onChangeUpgradeType}
            selectedUpgradeType={selectedUpgradeType}
            minItemSize={minItemSize}
          />
        )}
      </div>
      <div className="wai-content-item-footer">
        <ActionButton
          currentTradeSessionStartAt={data.current_trade_session.started_at}
          balance={data.current_trade_session.balance}
          maxBalance={data.current_trade_session.max_balance}
          onClaim={onClaim}
          remainingTime={remainingTime}
          canClaim={canClaim}
          payPassMultiplier={payPassMultiplier}
        />
      </div>
    </div>
  );
};
